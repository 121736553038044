import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// redux
import { Provider } from "react-redux";
import store from "./redux/store";

// context
import { AuthProvider } from "./context/authContext";

// Material UI

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
      <ToastContainer />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
