import { useState } from "react";

// Redux
import { useSelector } from "react-redux";

// Material UI
import { Box, Slider } from "@mui/material";
import { styled } from "@mui/system";

// Static
import { primaryColor } from "../../static/staticStyles";

const SliderContainer = styled("div")({
  display: "flex",
});

const SliderValue = styled("div")({
  padding: "0.5rem 2rem",
  marginLeft: "7rem",
  border: "1px solid #BDBDBD",
  borderRadius: "4px",
  width: "100px",
  textAlign: "center",
});

const Settings = ({ handleSettingUpdate }) => {
  const state_temperature = useSelector((state) => state.setting.temperature);
  const state_tokens = useSelector((state) => state.setting.max_tokens);

  // set defalut values from redux
  const [temperature, setTemperature] = useState(state_temperature);
  const [token, setToken] = useState(state_tokens);
  const handleTempUpdate = (val) => {
    setTemperature(val);
    handleSettingUpdate({ temperature: val });
  };
  const handleTokenUpdate = (val) => {
    setToken(val);
    handleSettingUpdate({ max_tokens: val });
  };
  return (
    <Box>
      <p>Temperature</p>
      <SliderContainer>
        <Slider
          defaultValue={state_temperature}
          step={0.1}
          getAriaValueText={handleTempUpdate}
          valueLabelDisplay="auto"
          min={0.2}
          max={1.2}
          sx={{
            color: primaryColor,
          }}
        />
        {/* <SliderValue>{temperature}</SliderValue> */}
      </SliderContainer>

      <p>Max Tokens</p>
      <SliderContainer>
        <Slider
          defaultValue={state_tokens}
          getAriaValueText={handleTokenUpdate}
          valueLabelDisplay="auto"
          min={250}
          max={2500}
          sx={{
            color: primaryColor,
          }}
        />
        {/* <SliderValue>{token}</SliderValue> */}
      </SliderContainer>
    </Box>
  );
};

export default Settings;
