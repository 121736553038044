import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  display_settings: false,
  modal: {
    id: 0,
    title: "GPT-3.5",
    name: "gpt-3.5-turbo",
    description:
      "GPT-3.5 models can understand and generate natural language or code. Our most capable and cost effective model in the GPT-3.5 family is gpt-3.5-turbo which has been optimized for chat but works well for traditional completions task as well.",
  },
  temperature: 0.8,
  max_tokens: 1000,
};

export const querySlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetSettingState: () => initialState,
    handleSettingDisplay: (state, action) => {
      state.display_settings = action.payload.display_settings;
    },
    updateSettings: (state, action) => {
      if (action.payload.max_tokens) {
        state.max_tokens = action.payload.max_tokens;
      }
      if (action.payload.temperature) {
        state.temperature = action.payload.temperature;
      }
      if (action.payload.modal) {
        state.modal = action.payload.modal;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { handleSettingDisplay, updateSettings, resetSettingState } =
  querySlice.actions;

export default querySlice.reducer;
