import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

// Context
import { useAuth } from "../context/authContext";

// Material UI
import { Typography, Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";

// Static
import { primaryColor } from "../static/staticStyles";

// Local components
import WithSidebar from "../components/sidebar/HOC/WithSidebar";
import SearchInputBox from "../components_v2/UI_Components/SearchInputBox";
import PromptCard from "../components_v2/UI_Components/PromptCard";

// Static
import { hideScrollbar } from "../static/staticStyles";
import { PrimaryCTA } from "../components_v2/UI_Components/PrimaryCTA";

const Container = styled(Box)({
  width: "inherit",
  justifyContent: "space-between",
  padding: "2rem",
  boxSizing: "border-box",
  minHeight: "100vh",
});

const HeaderContainer = styled(Box)({
  width: "100%",
  textAlign: "center",
});

const Heading = styled(Typography)({
  fontSize: "30px",
  fontWeight: 600,
  lineHeight: "38px",
  letterSpacing: "0em",
  textAlign: "center",
});

const SmallTextHeading = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
  color: "#475467",
});

const MainContainer = styled(Box)({
  width: "100%",
  padding: "1rem",
  marginTop: "1.5rem",
  borderRadius: "30px",
  height: "calc(100vh - 166px)",
  overflow: "auto",
  ...hideScrollbar,
});

const CardContainer = styled(Box)({
  paddingTop: "2rem",
});

const Title = styled(Typography)({
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "38px",
  letterSpacing: "0em",
  textAlign: "left",
  marginBottom: "1.5rem",
});

const GridCardContainer = styled(Box)(({ big_screen }) => ({
  display: "grid",
  gridTemplateColumns: big_screen
    ? "repeat(4, minmax(250px, 1fr))"
    : "repeat(auto-fit, minmax(250px, 1fr))",
  gap: "1rem",
}));

function Home() {
  const navigate = useNavigate();
  const big_screen = useMediaQuery("(min-width:1300px)");

  const { isAuthenticated } = useAuth();
  // auth0 authentication
  const { loginWithRedirect } = useAuth0();

  const [recentlyAdded, setRecentlyAdded] = useState(null);
  const [allPrompts, setAllPrompts] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getRecentlyAddedPrompts();
    getAllPrompts();
  }, []);

  const handleClick = () => {
    loginWithRedirect();
  };

  const getRecentlyAddedPrompts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/v1/prompt/recentlyAdded`
      );
      if (response.data.result.success) {
        setRecentlyAdded(response.data.result.prompts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllPrompts = async (query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/v1/prompt/all?page=1&limit=8${
          query ? `&search_query=${query}` : ``
        }`
      );
      if (response.data.result.success) {
        setAllPrompts(response.data.result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCardClick = ({ id }) => {
    navigate(`/use-prompt/${id}`);
  };

  return (
    <WithSidebar>
      <Container>
        <HeaderContainer>
          <Heading>Search and find the skill your need</Heading>
          <SmallTextHeading>What is your need today?</SmallTextHeading>
        </HeaderContainer>
        <MainContainer>
          <SearchInputBox
            handleSubmit={() => getAllPrompts(query)}
            handleOptionClick={() => getAllPrompts(query)}
            setQuery={setQuery}
          />
          <CardContainer style={{ height: "305px", overflow: "hidden" }}>
            <Title>New Tasks</Title>
            <GridCardContainer big_screen={big_screen}>
              {recentlyAdded?.length > 0
                ? recentlyAdded.map((val) => (
                    <PromptCard
                      title={val.title}
                      description={val.description}
                      handleDelete={null}
                      id={val.id}
                      onCardClick={handleCardClick}
                      width="100%"
                      bgColor={primaryColor}
                    />
                  ))
                : null}
            </GridCardContainer>
          </CardContainer>
          <CardContainer>
            <Title>Top tasks used</Title>
            <GridCardContainer big_screen={big_screen}>
              {allPrompts?.length > 0
                ? allPrompts.map((val) => (
                    <PromptCard
                      key={val.id}
                      title={val.title}
                      description={val.description}
                      handleDelete={null}
                      id={val.id}
                      onCardClick={handleCardClick}
                      width="100%"
                    />
                  ))
                : null}
            </GridCardContainer>
          </CardContainer>
        </MainContainer>
        {!isAuthenticated && (
          <Box sx={{ position: "fixed", top: "42px", right: "52px" }}>
            <PrimaryCTA ctaClick={handleClick}>Login</PrimaryCTA>
          </Box>
        )}
      </Container>
    </WithSidebar>
  );
}

export default Home;
