import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Material UI
import { Box } from "@mui/material";
import { styled } from "@mui/system";

// Local Components
import Sidebar from "../components/sidebar/Sidebar";
import RHS from "../components/rhs/RHS";
import SavePrompt from "../components/UIComponents/SavePrompt";
import ConfirmationModal from "../components/UIComponents/ConfirmationModal";
import WithSidebar from "../components/sidebar/HOC/WithSidebar";
import ContextSelection from "../components_v2/prompt_builder_page/ContextSelection";
import PromptData from "../components/sidebar/components/PromptData";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  updateSettings,
} from "../redux/slices/settingSlice";
import {
  handleConfirmationDisplay,
  handleContextSelection,
} from "../redux/slices/commonSlice";

// Hooks
import { useHandleAutosave } from "../helpers/hooks/useHandleAutosave";

// Assets
import taskSaved from "../assets/taskSaved.png";

const GridLayout = styled("div")({
  height: "100vh",
  display: "flex",
});

const GridLayoutLHS = styled("div")({
  minHeight: "100%",
});

const SidebarContainer = styled(Box)({
  position: "sticky",
  top: 0,
  width: "345px",
  height: "calc(100vh - 158px)",
});

function Layout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useSelector((state) => state.query.query_text);
  const { id, description } = useSelector((state) => state.save_prompt);

  const [cancelAutoSave, setCancelAutoSave] = useState(false);
  const [contextModalOpen, setContextModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  useHandleAutosave(cancelAutoSave);

  useEffect(() => {
    if (!!id) return;
    dispatch(
      handleContextSelection({
        display: true,
      })
    );
    setContextModalOpen(true);
  }, []);

  const handleSettingUpdate = (val) => {
    dispatch(updateSettings(val));
  };

  const handleSavePromptClose = (val) => {
    if (!!val?.id) {
      // stroing data in redux after saving prompt
      dispatch(
        handleConfirmationDisplay({ display: true, text: "Prompt Saved!" })
      );
      setCancelAutoSave(true);
    }
    setSaveModalOpen(false);
  };

  const handleSave = () => {
    if (query.replaceAll("&nbsp;", "").trim() === "") {
      return toast.warn("Enter a valid query", {
        position: "top-center",
        hideProgressBar: true,
        theme: "light",
      });
    }
    if (description.trim() === "") {
      return toast.warn("Enter description", {
        position: "top-center",
        hideProgressBar: true,
        theme: "light",
      });
    }
    setSaveModalOpen(true);
  };

  const handleClose = () => {
    dispatch(handleConfirmationDisplay({ display: false }));
    navigate("/all-prompts");
  };

  return (
    <div>
      <WithSidebar>
        <GridLayout container spacing={2}>
          <GridLayoutLHS item>
            <SidebarContainer>
              <PromptData />
              <Sidebar handleSettingUpdate={handleSettingUpdate} />
            </SidebarContainer>
          </GridLayoutLHS>
          <div style={{ flex: 1 }} item>
            <RHS openSaveModal={handleSave} />
          </div>
        </GridLayout>
      </WithSidebar>

      {/* save prompt modal */}
      <SavePrompt
        onClose={handleSavePromptClose}
        icon={taskSaved}
        heading="Task saved"
        description="This task has been updated. You wiill have access to this task from your workspace"
        ctaText="Confirm"
        open={saveModalOpen}
        error={false}
      />

      {/* confirmation modal */}
      <ConfirmationModal onClose={handleClose} />

      {/* for context selection */}
      <ContextSelection
        open={contextModalOpen}
        onClose={() => setContextModalOpen(false)}
      />
    </div>
  );
}

export default Layout;
