// Material UI
import { Box, Typography, Button, Modal } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { styled } from "@mui/system";
import { PrimaryCTA } from "./UI_Components/PrimaryCTA";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  borderRadius: "16px",
};

const ClearIcon = styled(ClearRoundedIcon)({
  cursor: "pointer",
  position: "absolute",
  top: 30,
  right: 30,
  color: "black",
});

const CTAContainer = styled(Box)({
  marginTop: "32px",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "1rem",
});

const Description = styled(Typography)({
  marginTop: "4px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "left",
});

const Title = styled(Typography)({
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "28px",
  letterSpacing: "0em",
  textAlign: "left",
  marginTop: "1rem",
});

const PromptUpdateModal = ({
  icon,
  heading,
  description,
  ctaText,
  onCTAClick,
  handleClose,
  open,
  error = true,
}) => {
  return (
    <Modal disableEnforceFocus open={open}>
      <Box sx={style}>
        <Box>
          <img src={icon} />
        </Box>
        <Box>
          <Title variant="h6" component="h2">
            {heading}
          </Title>
          <Description>{description}</Description>
        </Box>
        <CTAContainer>
          <Button
            sx={{ borderColor: "black", color: "black" }}
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {error ? (
            <Button variant="contained" color="error" onClick={onCTAClick}>
              {ctaText}
            </Button>
          ) : (
            <PrimaryCTA ctaClick={onCTAClick}>{ctaText}</PrimaryCTA>
          )}
        </CTAContainer>
        <Box>
          <ClearIcon onClick={handleClose} />
        </Box>
      </Box>
    </Modal>
  );
};

export default PromptUpdateModal;
