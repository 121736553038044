import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

// Material UI
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

// Context

const DialogRoot = styled(Dialog)({
  "& .MuiDialog-paper": {
    // height: "662px",
    width: "994px",
    padding: "51px 100px 90px 72px !important",
    borderRadius: "30px !important",
  },
});

const Title = styled(Typography)({
  fontWeight: 500,
  fontSize: "28px",
  paddingBottom: "51px",
});

const SaveIcon = styled(Button)({
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0.37)",
  width: "178px",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.37)",
  },
});

const BottomSection = styled(Box)({
  marginTop: "60px",
  display: "flex",
  justifyContent: "flex-end",
  gap: "30px",
});

const CancelCTA = styled(Button)({
  color: "black",
});

const TitleInput = styled(TextField)({
  width: "100%",
  height: "45px",
  "& div": {
    height: "inherit",
  },
});

const Label = styled(Typography)({
  marginBottom: "1rem",
});

const DescriptionInput = styled(TextField)({
  width: "100%",
});

function EditModal({
  open,
  onClose,
  provided_title,
  provided_description,
  id,
  handleEdit,
}) {
  const [title, setTitle] = useState({
    value: "",
    error: false,
  });
  const [description, setDescripton] = useState({
    value: "",
    error: false,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setTitle((val) => ({ ...val, value: provided_title }));
    setDescripton((val) => ({ ...val, value: provided_description }));
  }, [provided_title, provided_description]);

  const handleSavePrompt = async () => {
    setFormSubmitted(true);
    try {
      // validations - add error state later on
      if (title.value === "") {
        setTitle((val) => ({ ...val, error: true }));
      }
      if (description.value === "") {
        setDescripton((val) => ({ ...val, error: true }));
      }
      if (title.value === "" || description.value === "")
        return setFormSubmitted(false);

      handleEdit({ id, title: title.value, description: description.value });
      setFormSubmitted(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-center",
        hideProgressBar: true,
        theme: "light",
      });
      console.log(error);
      setFormSubmitted(false);
    }
  };

  return (
    <DialogRoot
      open={open}
      onClose={() =>
        onClose({
          title: title.value,
          description: description.value,
        })
      }
      fullWidth={true}
      maxWidth="lg"
    >
      <Title>Edit your prompt</Title>
      <DialogActions>
        <Box width={"100%"}>
          <Box>
            <Label>Title</Label>
            <TitleInput
              defaultValue={provided_title}
              autoFocus
              type="text"
              variant="outlined"
              onChange={(e) =>
                setTitle({ value: e.target.value, error: false })
              }
              error={title.error}
              placeholder="Enter title"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          </Box>
          <Box
            sx={{
              marginTop: "42px",
            }}
          >
            <Label>Description</Label>
            <DescriptionInput
              type="text"
              defaultValue={provided_description}
              rows={4}
              multiline
              onChange={(e) => {
                setDescripton({ value: e.target.value, error: false });
              }}
              error={description.error}
              variant="outlined"
              placeholder="Enter description"
            />
          </Box>
          <BottomSection>
            <CancelCTA
              onClick={() =>
                onClose({
                  title: title.value,
                  description: description.value,
                })
              }
              variant="text"
            >
              Cancel
            </CancelCTA>
            <SaveIcon
              onClick={handleSavePrompt}
              disabled={formSubmitted}
              variant="contained"
            >
              {formSubmitted ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Save"
              )}
            </SaveIcon>
          </BottomSection>
        </Box>
      </DialogActions>
    </DialogRoot>
  );
}

export default EditModal;
