import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  display_save_prompt: false,
  title: "Task Name",
  description: "",
  status: "in_progress",
};

export const querySlice = createSlice({
  name: "save_prompt",
  initialState,
  reducers: {
    resetSaveState: () => initialState,
    handleSavePromptDisplay: (state, action) => {
      state.display_save_prompt = action.payload.display_save_prompt;
    },
    handlePromptData: (state, action) => {
      if (action.payload.id) {
        state.id = action.payload.id;
      }
      if (action.payload.title) {
        state.title = action.payload.title;
      }
      if (action.payload.description) {
        state.description = action.payload.description;
      }
      if (action.payload.status) {
        state.status = action.payload.status;
      }
    },
    handleTitleChange: (state, action) => {
      state.title = action.payload.title;
    },
    handleDescChange: (state, action) => {
      state.description = action.payload.description;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handleSavePromptDisplay,
  handlePromptData,
  handleTitleChange,
  resetSaveState,
  handleDescChange,
} = querySlice.actions;

export default querySlice.reducer;
