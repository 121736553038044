import axios from "axios";
import { toast } from "react-toastify";

// Redux
import { useSelector } from "react-redux";

// Context
import { useAuth } from "../../context/authContext";

// Local component
import PromptUpdateModal from "../../components_v2/PromptUpdateModal";

function SavePrompt({ open, onClose, icon, heading, description, ctaText }) {
  const { user } = useAuth();
  const { max_tokens, temperature, modal } = useSelector(
    (state) => state.setting
  );
  const { query_text: query, variables: fields } = useSelector(
    (state) => state.query
  );
  const {
    id: promptID,
    title: promptTitle,
    description: promptDesc,
  } = useSelector((state) => state.save_prompt);
  const context = useSelector(
    (state) => state.common.context_Selection_modal.value
  );

  const handleSavePrompt = async () => {
    try {
      // if (promptTitle || promptDesc) return;
      if (!promptTitle) return;

      // display toast
      if (query.replaceAll("&nbsp;", "").trim() === "") {
        toast.warn("Add a valid query", {
          position: "top-center",
          hideProgressBar: true,
          theme: "light",
        });
        return;
        // display toast
      }

      const ID = promptID ?? Math.floor(Math.random() * 100000);
      // fetch data from slices
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/v1/prompt/save`,
        {
          id: ID,
          title: promptTitle,
          description: promptDesc,
          settings: {
            max_tokens,
            temperature,
            modal,
          },
          fields,
          query,
          status: "published",
          context,
          userId: user?.id,
        }
      );
      if (response.data?.result?.success) {
        onClose({ id: ID });
      }
      if (response.data?.error) {
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-center",
        hideProgressBar: true,
        theme: "light",
      });
      console.log(error);
    }
  };

  return (
    <PromptUpdateModal
      icon={icon}
      heading={heading}
      description={description}
      ctaText={ctaText}
      onCTAClick={handleSavePrompt}
      handleClose={onClose}
      open={open}
      error={false}
    />
  );
}

export default SavePrompt;
