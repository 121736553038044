import React, { createContext, useContext, useState } from "react";
import Cookies from "js-cookie";

// Redux
import { handleWorkspace } from "../redux/slices/withsidebarSlice";
import { useDispatch } from "react-redux";

// Helpers
import { handleWorkspaceState } from "../helpers/API_calls/handleWorkspacestate";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const getWorkspace = async ({ user }) => {
    try {
      const data = await handleWorkspaceState({ user });
      if (!!data) {
        dispatch(handleWorkspace({ list: data }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const login = ({ token, user, inExisting }) => {
    // global state
    setToken(token);
    setUser(user);
    setIsAuthenticated(true);

    // getting workspace data
    getWorkspace({ user });

    // updating token
    if (!inExisting) {
      Cookies.set("token", token, { expires: 30 }); // expires in 30 days
      Cookies.set("user", JSON.stringify(user), { expires: 30 }); // expires in 30 days
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    Cookies.remove("token", { path: "" });
    Cookies.remove("user", { path: "" });
  };

  const register = ({ token, user }) => {
    // global state
    setToken(token);
    setUser(user);
    setIsAuthenticated(true);

    // getting workspace data
    getWorkspace({ user });

    // updating token
    Cookies.set("token", token, { expires: 30 }); // expires in 30 days
    Cookies.set("user", JSON.stringify(user), { expires: 30 }); // expires in 30 days
  };

  return (
    <AuthContext.Provider
      value={{
        register,
        login,
        logout,
        isAuthenticated,
        user: typeof user === "string" ? JSON.parse(user) : user,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
