import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// Material UI
import { Box, Typography, Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// Local components
import OuterContainer from "../components/auth/OuterContainer";

// Helpers
import { validateEmail } from "../helpers/validateEmail";
import { validatePassword } from "../helpers/validatePassword";

// context
import { useAuth } from "../context/authContext";
import AuthRedirect from "../components/auth/AuthRedirect";

const Label = styled("label")({
  fontSize: "1rem",
  fontWeight: 500,
  marginBottom: "14px",
  display: "block",
});

const Input = styled(TextField)(({ isinputvalid }) => ({
  width: "637px",
  marginBottom: "2rem",
  "& input": {
    padding: "12px",
    height: "22px",
    ...(isinputvalid
      ? {
          border: "2px solid #6aa272",
          backgroundColor: "#e4f2e6 !important",
          borderRadius: "inherit",
          height: "18px",
        }
      : {}),
  },
}));

const SubmitCTA = styled(Button)({
  backgroundColor: "#E8EEFE",
  color: "black",
  width: "371px",
  fontSize: "20px",
  fontWeight: 500,
  textTransform: "none",
  display: "block",
  margin: "auto",
});

const Heading = styled(Typography)({
  marginTop: "41px",
  fontSize: "24px",
  fontWeight: 500,
  textAlign: "center",
});

const FormContainer = styled(Box)({
  margin: "40px 102px 0px",
});

const BottomSection = styled("div")({
  margin: "31px auto 39px",
  textAlign: "center",
  color: "#868686",
});

const LoginLink = styled(Link)({
  color: "black",
  textDecoration: "none",
});

const ServerErrorDisplay = styled("div")({
  textAlign: "center",
  color: "#d32f2f",
});

const IconBox = styled("span")({
  cursor: "pointer",
  position: "absolute",
  right: 10,
  top: 10,
});

function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const initialState = { email: "", password: "" };
  const [error, setError] = useState(initialState);
  const [formValues, setFormValues] = useState(initialState);
  const [validInput, setValidInput] = useState({
    email: false,
    password: false,
  });
  const [serverError, setServerError] = useState("");
  const [displayPassword, setDisplayPassword] = useState(false);

  useEffect(() => {
    setServerError("");
    setValidInput({
      email: validateEmail(formValues.email),
      password: validatePassword(formValues.password),
    });
  }, [formValues.email, formValues.password]);

  const disabled = !(validInput.email && validInput.password);

  const inputValidations = () => {
    let isError = false;
    if (!validateEmail(formValues.email)) {
      isError = true;
      setError((val) => ({ ...val, email: "Incorrect email format" }));
    }
    if (formValues.email.trim().length === 0) {
      isError = true;
      setError((val) => ({ ...val, email: "Email cannot be empty" }));
    }
    if (!validatePassword(formValues.password)) {
      isError = true;
      setError((val) => ({ ...val, password: "Enter valid password" }));
    }
    if (formValues.password.trim().length === 0) {
      isError = true;
      setError((val) => ({ ...val, password: "Password cannot be empty" }));
    }

    return !isError;
  };

  const handleLogin = async () => {
    // validations
    if (!inputValidations()) return;
    // Api call for register functionality
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/v1/auth/login`,
        formValues
      );

      if (response.status === 200) {
        login({
          token: response.data.result.token,
          user: response.data.result.user,
        });
        navigate("/home");
      }
    } catch (error) {
      console.log("An error occurred", error);
      setServerError(error.response.data.message);
    }
  };

  return (
    <OuterContainer>
      <Heading>Login</Heading>
      <FormContainer component="form">
        <Label>Email address</Label>
        <Input
          error={!!error.email}
          // defaultValue="Hello World"
          helperText={error.email}
          required
          isinputvalid={validInput.email}
          onChange={(event) => {
            setFormValues((val) => ({ ...val, email: event.target.value }));
            setError((val) => ({ ...val, email: "" }));
          }}
          placeholder="example@gmail.com"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleLogin();
            }
          }}
        />

        <Label>Password</Label>
        <div style={{ position: "relative" }}>
          <Input
            error={!!error.password}
            type={displayPassword ? "text" : "password"}
            helperText={error.password}
            required
            isinputvalid={validInput.password}
            onChange={(event) => {
              setFormValues((val) => ({
                ...val,
                password: event.target.value,
              }));
              setError((val) => ({ ...val, password: "" }));
            }}
            placeholder="Password"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleLogin();
              }
            }}
          />
          <IconBox onClick={() => setDisplayPassword(!displayPassword)}>
            {displayPassword ? (
              <VisibilityOffIcon color="action" />
            ) : (
              <VisibilityIcon color="action" />
            )}
          </IconBox>
        </div>
        <ServerErrorDisplay>{serverError}</ServerErrorDisplay>
        <BottomSection>
          Forgot your password?{" "}
          <LoginLink to="/forgot-password">Reset here</LoginLink>
        </BottomSection>
        <SubmitCTA disabled={disabled} onClick={handleLogin}>
          Login
        </SubmitCTA>
      </FormContainer>
      <BottomSection>
        Do not have an account?{" "}
        <LoginLink to="/register">Register here</LoginLink>
      </BottomSection>
    </OuterContainer>
  );
}

const AuthLogin = () => (
  <AuthRedirect>
    <Login />
  </AuthRedirect>
);

export default AuthLogin;
