import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { SSE } from "sse";
import { toast } from "react-toastify";
import parse from "html-react-parser";

// Material UI
import { Box, Button, CircularProgress, Paper } from "@mui/material";
import { styled } from "@mui/system";

// Local Components
import BuildTabs from "./components/BuildTabs";
import ModalAnswerTabs from "./components/ModalAnswerTabs";

// Helpers
import { extractTextContent, updateSpanText } from "../../helpers/spanTagUtils";

// Redux
import { useSelector } from "react-redux";
import { PrimaryCTA } from "../../components_v2/UI_Components/PrimaryCTA";
import { hideScrollbar, primaryColor } from "../../static/staticStyles";

const RHSSection = styled(Box)({
  padding: "32px 32px 32px 0px",
  position: "relative",
});

const SimulateCTABox = styled(Box)({
  display: "flex",
  gap: "2rem",
  marginBottom: "58px",
  justifyContent: "end",
});

const ResponseMetadata = styled("span")({
  justifyContent: "end",
  fontSize: "12px",
  display: "flex",
  gap: "1rem",
});

const CircularLoader = styled(CircularProgress)({
  height: "20px !important",
  width: "20px !important",
});

const TextQuery = styled(Paper)({
  backgroundColor: primaryColor,
  color: "white",
  height: "125px",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  padding: "1.5rem 1rem",
  overflowY: "auto",
  ...hideScrollbar,
  boxShadow: "0px 4px 4px 0px #00000040",
});

const ResponseBox = styled(Paper)({
  height: "calc(100vh - 309px)",
  marginTop: "10px",
  padding: "1rem",
  overflowY: "auto",
  ...hideScrollbar,
  boxShadow: "0px 4px 4px 0px #00000040",
  border: "1px solid black",
});

function RHS({ openSaveModal }) {
  const query = useSelector((state) => state.query.query_text);
  const variables = useSelector((state) => state.query.variables);
  const {
    max_tokens: state_maxTokens,
    temperature: state_temp,
    modal: state_modal,
  } = useSelector((state) => state.setting);
  const simulate_cta_disabled = useSelector(
    (state) => state.common.simulate_cta_disabled
  );
  const resultRef = useRef();
  const [response, setResponse] = useState("");
  const [APIResponse, setAPIResponse] = useState("");
  const [loadingTime, setLoadingTime] = useState({
    initial: 0,
    final: 0,
  });
  const [token, setToken] = useState({
    count: 0,
    cost: 0,
  });
  const [simulateDisabled, setSimulateDisabled] = useState(false);

  // this is displayed on screen
  const htmlQuery = updateSpanText(
    query,
    variables?.map((val) => ({
      value:
        typeof val.value === "object"
          ? val.value.name
          : val.value?.trim()
          ? val.value
          : val.name,
      id: val.id,
    }))
  );

  let json_htmlQuery; // if input type file is present in variables
  if (variables.filter((val) => val.input_type === "file").length > 0) {
    json_htmlQuery = updateSpanText(
      query,
      variables?.map((val) => ({
        value:
          typeof val.value === "object"
            ? val.value.data
            : val.value?.trim()
            ? val.value
            : val.name,
        id: val.id,
      }))
    );
  }

  // this is for API call
  const prompt_query = extractTextContent(json_htmlQuery ?? htmlQuery);

  const queryData = JSON.stringify({
    model: state_modal?.name,
    temperature: state_temp,
    max_tokens: state_maxTokens,
    stream: true,
    messages: [
      {
        role: "user",
        content: `Format the response as HTML content. ${prompt_query}`,
      },
    ],
  });

  useEffect(() => {
    resultRef.current = response;
  }, [response]);

  const generateResponse = async () => {
    setSimulateDisabled(true);
    // Validations
    if (prompt_query.trim() === "") {
      toast.warn("Enter a valid query", {
        position: "top-center",
        hideProgressBar: true,
        theme: "light",
      });
      setSimulateDisabled(false);
      return;
    }
    const var_array = variables.map((val) =>
      typeof val.value === "object" ? val.value : val.value.trim()
    );
    if (var_array.includes("")) {
      toast.warn("Enter all variable values", {
        position: "top-center",
        hideProgressBar: true,
        theme: "light",
      });
      setSimulateDisabled(false);
      return;
    }

    setResponse("");
    setAPIResponse("");
    setLoadingTime({ final: 0, initial: 0 });
    setToken({ count: 0, cost: 0 });
    const startTime = performance.now();
    try {
      // const res = await getResponseFromAI({ query: prompt_query });

      const API_KEY = process.env.REACT_APP_OPEN_API_KEY;

      const apiUrl = "https://api.openai.com/v1/chat/completions";
      // const apiResponse = await axios.post(apiUrl, data, { headers });

      let source = new SSE(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
        },
        method: "POST",
        payload: queryData,
      });

      let time = 0;
      source.addEventListener("message", (e) => {
        if (e.data !== "[DONE]") {
          setAPIResponse((val) => val + e.data);
          let payload = JSON.parse(e.data);
          let text = payload.choices[0].delta?.content;
          if (text !== "\n" && text !== undefined) {
            resultRef.current = resultRef.current + text;
            setResponse(resultRef.current);
            if (time === 0) {
              const endTime = performance.now();
              const timeInSeconds = (endTime - startTime) / 1000;
              setLoadingTime((val) => ({ ...val, initial: timeInSeconds }));
              time++;
            }
          }
        } else {
          source.close();
        }
      });

      source.addEventListener("readystatechange", (e) => {
        if (e.readyState >= 2) {
          const endTime = performance.now();
          const timeInSeconds = (endTime - startTime) / 1000;
          setLoadingTime((val) => ({ ...val, final: timeInSeconds }));
          getTokens();
          setSimulateDisabled(false);
        }
      });

      source.stream();
    } catch (error) {
      console.error("Failed to generate response:", error);
      setSimulateDisabled(false);
    }
  };

  const getTokens = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/v1/tokenize`,
        {
          value: `Format the response as HTML content. ${prompt_query} ${resultRef.current}`,
        }
      );
      setToken({ ...response.data.result });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <RHSSection>
      <SimulateCTABox>
        <Button
          style={{
            backgroundColor: "black",
            color: "white",
            width: "155px",
            textTransform: "unset",
          }}
          onClick={generateResponse}
          disabled={simulateDisabled || simulate_cta_disabled}
          variant="contained"
        >
          Simulate task
        </Button>
        <PrimaryCTA ctaClick={openSaveModal}>Save task</PrimaryCTA>
      </SimulateCTABox>

      <TextQuery>
        {htmlQuery
          ? parse(htmlQuery)
          : "Start your set up by adding an action and parameters"}
      </TextQuery>

      <ResponseBox>
        {response ? (
          <ResponseMetadata>
            {loadingTime.initial !== 0 ? (
              <i>loading (initial): {loadingTime.initial.toFixed(1)} sec</i>
            ) : null}
            {loadingTime.final !== 0 ? (
              <i>loading (final): {loadingTime.final.toFixed(1)} sec</i>
            ) : null}
            {loadingTime.initial !== 0 && loadingTime.final === 0 ? (
              <span style={{ padding: "0 0 10px 40px" }}>
                <CircularLoader />
              </span>
            ) : null}
            {token.count !== 0 ? <i>Tokens: {token.count}</i> : null}
            {token.cost !== 0 ? (
              <i>Estimated cost: {token.cost.toFixed(2)} ¢</i>
            ) : null}
          </ResponseMetadata>
        ) : null}
        {parse(response)}
      </ResponseBox>
    </RHSSection>
  );
}

export default RHS;
