import { useEffect, useState } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  handlePromptData,
  resetSaveState,
} from "../../redux/slices/savePromptSlice";
import { resetQueryState } from "../../redux/slices/querySlice";
import { resetSettingState } from "../../redux/slices/settingSlice";

// Helpers
import { handleAutosave } from "../API_calls/handleAutosave";

// Context
import { useAuth } from "../../context/authContext";

export const useHandleAutosave = (preventAutosave = false) => {
  /**
   * to auto save prompt 5 seconds after any activity
   */
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { max_tokens, temperature, modal } = useSelector(
    (state) => state.setting
  );
  const { query_text: query, variables: fields } = useSelector(
    (state) => state.query
  );
  const context = useSelector(
    (state) => state.common.context_Selection_modal.value
  );
  const {
    id: promptID,
    title,
    description,
    status,
  } = useSelector((state) => state.save_prompt);
  const [timer, setTimer] = useState(null);

  const handleSaveID = (tempID) => {
    dispatch(handlePromptData({ id: tempID }));
  };

  useEffect(() => {
    if (preventAutosave) {
      clearTimeout(timer);
      return;
    }

    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(
        () =>
          handleAutosave({
            query,
            promptID,
            title,
            description,
            max_tokens,
            temperature,
            modal,
            fields,
            status,
            context,
            handleSaveID,
            UserId: user.id,
          }),
        [5000]
      )
    );
    return () => {
      clearTimeout(timer);
    };
  }, [
    query,
    max_tokens,
    temperature,
    modal,
    title,
    description,
    preventAutosave,
    status,
    fields,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetQueryState());
      dispatch(resetSaveState());
      dispatch(resetSettingState());
    };
  }, []);
};
