import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

// Context
import { useAuth } from "../context/authContext";

// Material UI
import { Box, LinearProgress } from "@mui/material";

const AuthorizeUser = () => {
  // auth0 authentication
  const { login } = useAuth();
  const { user } = useAuth0();

  const navigate = useNavigate();

  //   Received user information from useAuth0
  //   user: {
  //     "given_name": "Vidhi ",
  //     "family_name": "Thakur",
  //     "nickname": "vidhi.thakur",
  //     "name": "Vidhi Thakur",
  //     "picture": "https://lh3.googleusercontent.com/a/ACg8ocJKKdHT0ZnX-NJcjs2n6iq0RnY_AcmRHNkqvN4gV-bKaQ=s96-c",
  //     "locale": "en",
  //     "updated_at": "2023-10-04T08:33:17.469Z",
  //     "email": "vidhi.thakur@decabits.com",
  //     "email_verified": true,
  //     "sub": "google-oauth2|115539237044969818048"
  // }

  useEffect(() => {
    if (!!user?.email) {
      authenticateUser();
    }
  }, [user]);

  const authenticateUser = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/v1/auth/oAuth/verify`,
        {
          email: user.email,
          name: user.name,
          avatar: user.picture,
        }
      );
      if (response.status === 200) {
        login({
          token: response.data.result.token,
          user: response.data.result.user,
        });
        navigate("/home");
      }
    } catch (error) {
      console.log("An error occurred", error);
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  );
};

export default AuthorizeUser;
