// Helpers
import { getSmallerNumber } from "./getSmallerNumber";

export const extractLastHTMLTagAndDataId = (content) => {
  const temp_str = content;

  // Remove the last closing HTML tag
  const lastTagIndex_close = content.lastIndexOf("</");
  const last_tag = content.slice(
    lastTagIndex_close + 2,
    content.lastIndexOf(">")
  );

  const lastTagIndex_open = content.lastIndexOf(`<${last_tag}`);
  content = content.slice(0, lastTagIndex_open);

  // logic for removing variable
  const deleted_str = temp_str.slice(lastTagIndex_open, lastTagIndex_close);
  const index_id_start = deleted_str.indexOf("data-id=") + 8;
  const tagSubstring = deleted_str.slice(index_id_start);
  const index_id_end = getSmallerNumber(
    tagSubstring.indexOf(">"),
    tagSubstring.indexOf(" ")
  );

  const dataId = tagSubstring.slice(0, index_id_end);
  return {
    content,
    id: +dataId,
  };
};
