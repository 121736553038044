// Material UI
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

// Assets
import avatarIcon from "../../assets/avatarIcon.svg";

// Local components
import { PrimaryCTA } from "./PrimaryCTA";

const StyledHeading = styled(Typography)({
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "28px",
  letterSpacing: "0em",
  textAlign: "center",
});

const StyledSubheading = styled(Typography)({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "center",
  marginTop: 4,
});

const RootContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "24px 0px",
  boxShadow: "0px 20px 24px -4px #10182814",
  borderBottomLeftRadius: "12px",
  borderBottomRightRadius: "12px",
});

const GetStarted = ({ heading, subHeading, handleClick }) => {
  return (
    <RootContainer>
      <img src={avatarIcon} width={120} height={56} />

      <Box sx={{ margin: "24px 0px 60px" }}>
        <StyledHeading>{heading}</StyledHeading>
        <StyledSubheading>{subHeading}</StyledSubheading>
      </Box>

      <Box width={429}>
        <PrimaryCTA ctaClick={handleClick}>Get Started</PrimaryCTA>
      </Box>
    </RootContainer>
  );
};

export default GetStarted;
