import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

// Material UI
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

// Local Components
import SelectPrompt from "../components/UIComponents/workspace/SelectPrompt";
import DisplayPrompts from "../components/UIComponents/workspace/DisplayPrompts";
import { PrimaryCTA } from "../components_v2/UI_Components/PrimaryCTA";

// Context
import { useAuth } from "../context/authContext";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { handleWorkspace } from "../redux/slices/withsidebarSlice";

const WorkspaceName = styled(Typography)({
  fontSize: "28px",
  fontWeight: 500,
  "&:focus": {
    outline: "none",
  },
});

const WorkspaceInterface = styled("div")({
  minHeight: "100vh",
});

const DoneIcon = styled(DoneRoundedIcon)({
  cursor: "pointer",
  color: "#1976d2",
  width: "30px",
  height: "30px",
});

const Header = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "32px 20px 0px 32px",
});

const TitleBox = styled(Box)(({ isEditing }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "4px 10px",
  minWidth: "304px",
  width: "fit-content",
  border: `1px solid transparent`,
  gap: "1.5rem",
  ...(isEditing
    ? {
        border: `1px solid #1976d2`,
        borderRadius: "4px",
        boxShadow: "1px 2px 1px rgba(0,0,0,0.1)",
      }
    : {}),
}));

const Workspace = () => {
  const { slug } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const workspaceStatus = useSelector((state) => state.withsidebar.workspace);

  const [prompts, setPrompts] = useState([]);
  const [isNameEditing, setIsNameEditing] = useState(false);
  const [workspaceName, setworkspaceName] = useState("");
  const [isAddingPrompts, setIsAddingPrompts] = useState(false);
  const [promptState, setPromptState] = useState({
    page: 1,
    totalPages: 1,
  }); // this state is for DisplayPrompts component

  useEffect(() => {
    setIsAddingPrompts(false);
  }, [slug]);

  const editWorkspace = async (old_slug) => {
    setIsNameEditing(false);
    if (workspaceName.trim() === "") return;
    // save workspace name in local state and add it in backend as well
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/v1/workspace/edit`,
        {
          UserId: user.id,
          name: workspaceName,
          slug: old_slug,
        }
      );
      if (response.data?.status === 200) {
        const slug = response.data.result.slug;
        const list = workspaceStatus.list;
        dispatch(
          handleWorkspace({
            list: list.map((item) => {
              if (item.slug === old_slug) {
                return { name: workspaceName, slug };
              } else {
                return item;
              }
            }),
          })
        );
        navigate(`/workspace/${slug}`);
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-center",
        hideProgressBar: true,
        theme: "light",
      });
    }
  };

  return (
    <WorkspaceInterface>
      <Header>
        <TitleBox isEditing={isNameEditing}>
          <WorkspaceName
            onDoubleClick={() => {
              setIsNameEditing(true);
            }}
            onKeyDown={(event) => {
              if (event.target.innerText.length > 35) {
                event.preventDefault();
              }
            }}
            onBlur={(event) => {
              setworkspaceName(event.target.innerText);
            }}
            contentEditable={isNameEditing}
          >
            {workspaceName ?? "Name of the workspace"}
          </WorkspaceName>
          {isNameEditing ? (
            <DoneIcon onClick={() => editWorkspace(slug)} />
          ) : null}
        </TitleBox>
        {isAddingPrompts ? (
          <Box width="fit-content">
            <PrimaryCTA ctaClick={() => setIsAddingPrompts(false)}>
              Save your workspace
            </PrimaryCTA>
          </Box>
        ) : null}
      </Header>

      {!isAddingPrompts ? (
        <DisplayPrompts
          prompts={prompts}
          slug={slug}
          setIsAddingPrompts={setIsAddingPrompts}
          setworkspaceName={setworkspaceName}
          setPrompts={setPrompts}
          promptState={promptState}
          setPromptState={setPromptState}
          userId={user.id}
        />
      ) : null}

      {isAddingPrompts ? (
        <SelectPrompt
          setIsAddingPrompts={setIsAddingPrompts}
          slug={slug}
          existingPrompts={prompts}
        />
      ) : null}
    </WorkspaceInterface>
  );
};

export default Workspace;
