import { useState } from "react";

// Material UI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

const Title = styled(Typography)({
  fontSize: "20px",
  fontWeight: 500,
  marginBottom: "20px",
});

const CardBox = styled(Card)(({ width, bgColor }) => ({
  width,
  height: 197,
  padding: "19px 20px",
  position: "relative",
  backgroundColor: bgColor,
  color: "white",
  boxShadow: "0px 1px 2px 0px #1018280D",
  borderRadius: "8px",
  minWidth: "250px",
}));

const Content = styled(CardContent)({
  padding: 0,
  marginBottom: "46px",
  cursor: "pointer",
});

const Description = styled(Typography)({
  fontSize: "16px",
  fontWeight: 300,
  display: "-webkit-box",
  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": "5",
  overflow: "hidden",
  textOverflow: "ellipsis",
  height: "110px",
});

export default function PromptCard({
  title,
  description,
  handleDelete,
  id,
  onCardClick,
  width = "305px",
  bgColor = "#475467",
}) {
  const [hideCard, setHideCard] = useState(false);
  const handleDeleteClick = () => {
    setHideCard(true);
    handleDelete && handleDelete(id);
  };

  const handleCardClick = async () => {
    onCardClick && onCardClick({ title, description, id });
  };

  if (hideCard) return null;
  return (
    <CardBox width={width} bgColor={bgColor} enableDelete={!!handleDelete}>
      <Content onClick={handleCardClick}>
        <Title variant="h5" component="div">
          {title}
        </Title>
        <Description variant="body2">{description}</Description>
      </Content>
      {!!handleDelete ? (
        <ClearRoundedIcon
          onClick={handleDeleteClick}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: 10,
            right: 10,
            color: "white",
          }}
        />
      ) : null}
    </CardBox>
  );
}
