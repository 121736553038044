import { createSlice } from "@reduxjs/toolkit";

export const withsidebarSlice = createSlice({
  name: "withsidebar",
  initialState: {
    workspace: {
      list: [],
      is_open: false,
    },
    is_sidebar_open: true,
  },
  reducers: {
    handleWorkspace: (state, action) => {
      if (action.payload.list !== undefined) {
        state.workspace.list = action.payload.list;
      }
      if (action.payload.is_open !== undefined) {
        state.workspace.is_open = action.payload.is_open;
      }
    },
    handleWorkspaceEdit: (state, action) => {
      const slug = action.payload.slug;
      state.workspace.list = state.workspace.list.map((l) => {
        if (l.slug === slug) {
          return {
            name: "",
            slug,
          };
        } else return l;
      });
    },
    handleWorkspaceDelete: (state, action) => {
      const slug = action.payload.slug;
      state.workspace.list = state.workspace.list.filter(
        (l) => l.slug !== slug
      );
    },
    toggleSidebar: (state, action) => {
      state.is_sidebar_open = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handleWorkspace,
  toggleSidebar,
  handleWorkspaceEdit,
  handleWorkspaceDelete,
} = withsidebarSlice.actions;

export default withsidebarSlice.reducer;
