export const validatePassword = (password) => {
  // Minimum length requirement
  const minLength = 8;

  // Regular expressions for character types
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const digitRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-]/;

  // Check if password meets the requirements
  const hasMinLength = password.length >= minLength;
  const hasUppercase = uppercaseRegex.test(password);
  const hasLowercase = lowercaseRegex.test(password);
  const hasDigit = digitRegex.test(password);
  const hasSpecialChar = specialCharRegex.test(password);

  return (
    hasMinLength && hasUppercase && hasLowercase && hasDigit && hasSpecialChar
  );
};
