import { useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";

// Material UI
import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  handleQueryUpdate,
  handleRemoveVariable,
  handleVarMiddleState,
  updateVariableInput,
} from "../../../redux/slices/querySlice";
import { handleSimulateState } from "../../../redux/slices/commonSlice";

// Local Components
import Editor from "../../UIComponents/Editor";
import { PrimaryCTA } from "../../../components_v2/UI_Components/PrimaryCTA";
import AddFields from "./AddFields";
import CustomFileInput from "../../../components_v2/UI_Components/CustomFileInput";

// Helpers
import {
  getCursorPosition,
  getHtmlBeforeAndAfterCursor,
} from "../../../helpers/getCursorPosition";

// Static
import { primaryColor } from "../../../static/staticStyles";

const FieldInfo = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  padding: "1rem",
  backgroundColor: primaryColor,
  color: "white",
  "& p": {
    margin: 0,
  },
});

const VariableContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "22px",
  "& $addFieldCTA": {
    color: "black",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
    "&:hover": {
      color: "black",
      backgroundColor: "rgba(0, 0, 0, 0.37)",
    },
  },
});

const VariableInput = styled(TextField)({
  borderRadius: 0,
  width: "100%",
  "& div": {
    borderRadius: "inherit",
  },
  "& input": {
    height: "fit-content",
  },
  "& input::-webkit-file-upload-button": {
    visibility: "hidden",
    marginLeft: "-80px",
  },
  "& input::before": {
    content: "'Download'",
    display: "inline-block",
    background: "#A7A7A7",
    borderRadius: "4px",
    padding: "5px 34px",
    outline: "none",
    whiteSpace: "nowrap",
    "-webkit-user-select": "none",
    cursor: "pointer",
    fontWeight: 500,
    fontSize: "14px",
  },
});

const DeleteCTA = styled("p")({
  cursor: "pointer",
  marginBottom: 0,
  textAlign: "right",
});

const EditorInfoText = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "left",
  marginTop: "0.5rem",
  color: "#475467",
});

const EditorLabel = styled(Typography)({
  fontSize: "15px",
  fontWeight: 700,
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "left",
});

function Fields({ handleEditing, handledisplayModal, isEditing }) {
  const editorRef = useRef();
  const dispatch = useDispatch();
  const variables = useSelector((state) => state.query.variables);
  const query_text = useSelector((state) => state.query.query_text);

  const handleEditorChange = ({ html, var_id = null }) => {
    dispatch(
      handleQueryUpdate({
        value_text: html,
      })
    );
    if (var_id !== null) {
      dispatch(
        handleRemoveVariable({
          var_id,
        })
      );
    }
  };

  const handleClickAdd = () => {
    // check cursor location first
    const cursorPosition = getCursorPosition(editorRef);
    const l = editorRef.current.innerText.length;
    if (cursorPosition !== l) {
      const { htmlBeforeCursor, htmlAfterCursor } =
        getHtmlBeforeAndAfterCursor(editorRef);

      dispatch(
        handleVarMiddleState({
          value: true,
          start: htmlBeforeCursor,
          end: htmlAfterCursor,
        })
      );
    } else {
      dispatch(
        handleVarMiddleState({
          value: false,
        })
      );
    }
    handleEditing(false);
  };

  return (
    <Box sx={{ marginTop: "24px" }}>
      <EditorLabel>Task to complete</EditorLabel>
      <Box sx={{ marginTop: "0.5rem" }}>
        <Editor
          value={query_text}
          onChange={handleEditorChange}
          editorRef={editorRef}
          variables={variables}
        />
      </Box>

      <EditorInfoText>
        Indicates the basic task that you want to proceed. Add variables for
        values required at usage time.
      </EditorInfoText>

      {isEditing ? (
        <>
          <Box width={"100%"} mt={3} mb={1}>
            <PrimaryCTA ctaClick={handleClickAdd}>
              <AddIcon /> Add variables
            </PrimaryCTA>
          </Box>

          <Variables variables={variables} />
        </>
      ) : (
        <AddFields handledisplayModal={handledisplayModal} />
      )}
    </Box>
  );
}

const Variables = ({ variables }) => {
  {
    /* individual fields for adding value to variables */
  }
  const dispatch = useDispatch();

  const handleFileUpload = async ({ file, id }) => {
    if (!file) return;
    // show error for file size > 50kb. For 1MB, 1024 * 1024
    if (file.size > 50 * 1024) {
      return toast.error("File size exceeds limit. Try again.");
    }
    dispatch(
      handleSimulateState({
        disabled: true,
      })
    );
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/v1/upload-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data?.result?.success) {
        dispatch(
          updateVariableInput({
            var_id: id,
            var_value: {
              name: response.data?.result?.file_name,
              data: response.data?.result?.data,
            },
          })
        );
        dispatch(
          handleSimulateState({
            disabled: false,
          })
        );
        toast.success("File data extracted");
      } else {
        toast.error("File upload failed");
      }
    } catch (error) {
      toast.error("Error uploading file");
      console.error("Error uploading file:", error);
    }
  };

  const handleRemoveVar = (id) => {
    dispatch(
      handleRemoveVariable({
        var_id: id,
      })
    );
  };

  const handleVariableInput = async ({
    id,
    e,
    allowed_formats,
    input_type,
  }) => {
    if (input_type !== "file") {
      dispatch(
        updateVariableInput({
          var_id: id,
          var_value: e.target.value,
        })
      );
      return;
    }
    const value = e.target.value;
    const ext = value.split(".")[value.split(".").length - 1];

    if (!allowed_formats.includes(ext)) {
      return toast.error("Format not allowed", {
        position: "top-center",
        hideProgressBar: true,
        theme: "light",
      });
    }
    await handleFileUpload({ file: e.target.files[0], id });
  };
  return (
    <VariableContainer>
      {variables
        .filter((val) => val.display)
        .map(
          ({
            id,
            name: input_name,
            placeholder,
            input_type,
            rows,
            allowed_formats,
            value,
          }) => (
            <Box key={id}>
              <Typography m={1}>{input_name}</Typography>
              <FieldInfo>
                <p>Name label : {placeholder}</p>
                <p>id : {id}</p>
              </FieldInfo>
              {input_type === "file" ? (
                <CustomFileInput
                  onFileChange={(e) =>
                    handleVariableInput({
                      id,
                      e,
                      allowed_formats,
                      input_type,
                    })
                  }
                />
              ) : (
                <VariableInput
                  id={`${input_type}-${id}`}
                  multiline={input_type === "text"}
                  rows={rows ?? 1}
                  type={input_type ?? "text"}
                  onChange={(e) =>
                    handleVariableInput({
                      id,
                      e,
                      allowed_formats,
                      input_type,
                    })
                  }
                  defaultValue={typeof value === "object" ? "" : value}
                  placeholder="Indicate your text"
                  inputProps={{ accept: allowed_formats }}
                />
              )}
              <DeleteCTA onClick={() => handleRemoveVar(id)}>
                Delete field
              </DeleteCTA>
            </Box>
          )
        )}
    </VariableContainer>
  );
};

export default Fields;
