import { Paper } from "@mui/material";

const SearchModal = ({ element }) => {
  return (
    <Paper
      sx={{
        position: "absolute",
        zIndex: 10,
        width: "100%",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        height: "fit-content",
      }}
    >
      <div>{element}</div>
    </Paper>
  );
};

export default SearchModal;
