import { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

// Material UI
import { styled } from "@mui/material/styles";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  TextField,
  ListItemIcon,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import AddIcon from "@mui/icons-material/Add";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

// Local component
import ContextMenu from "./ContextMenu";
import { toast } from "react-toastify";

const ListIcon = styled(ListItemIcon)(({ open }) => ({
  minWidth: 0,
  width: "24px",
  height: "24px",
  marginRight: open ? "24px" : "auto",
  justifyContent: "center",
  color: "white",
}));

const ListButton = styled(ListItemButton)(({ open }) => ({
  minHeight: 48,
  justifyContent: open ? "initial" : "center",
  padding: "8px 10px 8px 15px",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const AddIconCTA = styled(AddIcon)({
  height: 14,
  width: 14,
  border: "1px solid white",
  borderRadius: "2px",
  marginLeft: "4px",
});

const DoneIcon = styled(DoneRoundedIcon)({
  height: 20,
  width: 20,
  marginLeft: "4px",
  color: "#1976d2",
});

const menuList = [
  {
    name: "Home",
    icon: <HomeOutlinedIcon />,
    redirectTo: "/",
    has_sublist: false,
  },
  {
    name: "Projects",
    icon: <DvrOutlinedIcon />,
    has_sublist: true,
  },
  {
    name: "Tasks",
    icon: <EditNoteOutlinedIcon />,
    redirectTo: "/all-prompts",
    has_sublist: false,
  },
];

const DrawerBody = ({
  open,
  handleOpen,
  workspaceStatus,
  setWorkspaceStatus,
  handleListitemClick,
  UserId,
  navigate,
}) => {
  const location = useLocation();
  const [newWorkspaceVal, setNewWorkspaceVal] = useState("");
  const handleSublistClick = () => {
    setWorkspaceStatus({ is_open: !workspaceStatus.is_open });
  };

  const handleNewWorksapce = () => {
    const list = workspaceStatus.list;
    const can_update =
      list.length === 0 ||
      list.find((val) => val.name.trim() === "") === undefined;

    if (can_update) {
      setWorkspaceStatus({
        is_open: true,
        list: [...workspaceStatus.list, { name: "", slug: "" }],
      });
    }
  };

  const addWorkspace = async () => {
    if (newWorkspaceVal.trim() === "") return;
    // save workspace name in local state and add it in backend as well
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/v1/workspace/add`,
        {
          UserId,
          name: newWorkspaceVal,
        }
      );
      if (response.data?.status === 200) {
        const slug = response.data.result.slug;
        const list = workspaceStatus.list;
        setWorkspaceStatus({
          list: list.map((item, index) => {
            if (index === list.length - 1) {
              return { name: newWorkspaceVal, slug };
            } else {
              return item;
            }
          }),
        });
        navigate(`/workspace/${slug}`);
      }
    } catch (error) {
      const list = workspaceStatus?.list?.slice(0, -1);
      setWorkspaceStatus({
        list,
      });
      // show error toast
      toast.error("Something went wrong", {
        position: "bottom-center",
        hideProgressBar: true,
        theme: "light",
      });
    }
  };

  const editWorkspace = async (old_slug) => {
    if (newWorkspaceVal.trim() === "") return;
    // save workspace name in local state and add it in backend as well
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/v1/workspace/edit`,
        {
          UserId,
          name: newWorkspaceVal,
          slug: old_slug,
        }
      );
      if (response.data?.status === 200) {
        const slug = response.data.result.slug;
        const list = workspaceStatus.list;
        setWorkspaceStatus({
          list: list.map((item) => {
            if (item.slug === old_slug) {
              return { name: newWorkspaceVal, slug };
            } else {
              return item;
            }
          }),
        });
        navigate(`/workspace/${slug}`);
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-center",
        hideProgressBar: true,
        theme: "light",
      });
    }
  };

  const getBgColor = (is_list, redirectTo) => {
    if (is_list && !!redirectTo && location.pathname === redirectTo) {
      return "#7F56D9";
    } else if (
      !is_list &&
      location.pathname.split("/").reverse()[0] === redirectTo
    ) {
      return "#7F56D9";
    } else return "transparent";
  };

  return (
    <List
      sx={{
        padding: "0px 15px",
      }}
    >
      {menuList.map(({ icon, name, redirectTo, has_sublist }) => (
        <ListItem
          key={name}
          disablePadding
          sx={{
            display: "block",
            backgroundColor: getBgColor(true, redirectTo),
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: getBgColor(true, redirectTo),
            },
          }}
        >
          <ListButton disableRipple open={open}>
            <ListIcon open={open} onClick={handleOpen}>
              {icon}
            </ListIcon>
            <ListItemText
              primary={name}
              onClick={() => handleListitemClick(redirectTo)}
              sx={{ opacity: open ? 1 : 0 }}
            />
            {has_sublist && open ? (
              workspaceStatus.is_open ? (
                <ExpandLess
                  sx={{ height: 22, width: 22 }}
                  onClick={handleSublistClick}
                />
              ) : (
                <ExpandMore
                  sx={{ height: 22, width: 22 }}
                  onClick={handleSublistClick}
                />
              )
            ) : null}
            {has_sublist && open ? (
              <AddIconCTA onClick={handleNewWorksapce} />
            ) : null}
          </ListButton>
          {has_sublist && (
            <Collapse in={workspaceStatus.is_open} timeout="auto" unmountOnExit>
              <SubList
                workspaceList={workspaceStatus?.list ?? []}
                handleListitemClick={handleListitemClick}
                getBgColor={getBgColor}
                setNewWorkspaceVal={setNewWorkspaceVal}
                addWorkspace={addWorkspace}
                editWorkspace={editWorkspace}
              />
            </Collapse>
          )}
        </ListItem>
      ))}
    </List>
  );
};

const SubList = ({
  workspaceList,
  handleListitemClick,
  getBgColor,
  setNewWorkspaceVal,
  addWorkspace,
  editWorkspace,
}) => {
  const onSubmit = (item) => {
    if (!!item?.slug) {
      editWorkspace(item?.slug);
    } else {
      addWorkspace();
    }
  };

  const workspaceUpdate = (e, item) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!!item?.slug) {
        editWorkspace(item?.slug);
      } else {
        addWorkspace();
      }
    }
  };
  return (
    <List component="div" disablePadding>
      {workspaceList?.map((item) => (
        <ContextMenu
          item={item}
          key={item?.name}
          element={
            <ListItemButton
              onClick={
                item?.name === ""
                  ? null
                  : () => handleListitemClick(`/workspace/${item?.slug}`)
              }
              sx={{
                pl: 11,
                backgroundColor: getBgColor(false, item?.slug),
                borderRadius: "6px",
                "&:hover": {
                  backgroundColor: getBgColor(false, item?.slug),
                },
              }}
            >
              {item?.name === "" && (
                <>
                  <TextField
                    size="small"
                    sx={{
                      "& input": {
                        color: "white",
                      },
                    }}
                    // color="secondary"
                    hiddenLabel
                    placeholder="New workspace"
                    type="text"
                    onChange={(e) => setNewWorkspaceVal(e.target.value)}
                    variant="filled"
                    autoFocus
                    onKeyDown={(e) => workspaceUpdate(e, item)}
                  />
                  <DoneIcon
                    sx={{ color: "white" }}
                    onClick={() => onSubmit(item)}
                  />
                </>
              )}
              {item?.name !== "" && <ListItemText primary={item?.name} />}
            </ListItemButton>
          }
        />
      ))}
    </List>
  );
};

export default DrawerBody;
