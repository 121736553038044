import axios from "axios";

export const handleAutosave = async ({
  query,
  promptID,
  title,
  description,
  max_tokens,
  temperature,
  modal,
  fields,
  status,
  context,
  handleSaveID,
  UserId,
}) => {
  if (!query.trim()) {
    return;
  }

  const tempID = Math.floor(Math.random() * 100000);

  const ID = promptID ?? tempID;
  // fetch data from slices
  const response = await axios.post(
    `${process.env.REACT_APP_PORT}/v1/prompt/auto-save`,
    {
      id: ID,
      title,
      description,
      settings: {
        max_tokens,
        temperature,
        modal,
      },
      fields,
      query,
      status,
      context,
      UserId,
    }
  );
  if (response.data?.result?.success) {
    if (!promptID) {
      handleSaveID && handleSaveID(tempID);
    }
    return "Content Auto Saved!";
  }
  if (response.data?.error) {
    return "Error occured";
  }
};
