import { useState } from "react";
import { InstantSearch, SearchBox, Hits, Configure } from "react-instantsearch";
import algoliasearch from "algoliasearch/lite";

// Material UI
import { Paper, Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledSearchBox = styled(Box)({
  maxWidth: "637px",
  margin: "0rem auto",
  position: "relative",
  "& li": {
    padding: "0.5rem",
    "&:hover": {
      backgroundColor: "#EDEDF0",
    },
  },
});

const StyledSearchInput = styled(SearchBox)({
  border: "1px solid #D0D5DD",
  backgroundColor: "#D0D5DD",
  boxShadow: "0px 1px 2px 0px #1018280D",
  borderRadius: "8px",
});

const StyledModal = styled(Paper)({
  position: "absolute",
  zIndex: 10,
  width: "100%",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  height: "fit-content",
});

const Options = styled("div")({
  cursor: "pointer",
  width: "100%",
});

const searchClient = algoliasearch(
  "W6NVMYO3C8",
  "2c6dcfb441e47528aa376f015a5a58ab"
);

const SearchInputBox = ({
  handleSubmit,
  handleOptionClick,
  setQuery,
  width = "100%",
}) => {
  const [timer, setTimer] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [searchModalDisplay, setSearchModalDisplay] = useState(false);

  const handleSearchQuery = (query, search) => {
    setSearchInput(query);
    setSearchModalDisplay(!!query);
    setQuery && setQuery(query);
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        if (query.trim()) {
          search(query);
        }
      }, 1500)
    );
  };

  const handleClick = (hit) => {
    handleOptionClick(hit);
    setSearchModalDisplay(false);
  };

  return (
    <InstantSearch searchClient={searchClient} indexName="prompts" insights>
      <Configure hitsPerPage={6} />
      <StyledSearchBox>
        <StyledSearchInput
          placeholder="Search a prompt"
          queryHook={handleSearchQuery}
          onSubmit={handleSubmit}
          style={{ width }}
        />
        {searchInput?.trim() && searchModalDisplay ? (
          <SearchModal
            style={{
              "& li": {
                "&:hover": {
                  backgroundColor: "greem",
                },
              },
            }}
            element={
              <Hits
                hitComponent={({ hit }) => (
                  <Options onClick={() => handleClick(hit)}>
                    {hit.title}
                  </Options>
                )}
              />
            }
          />
        ) : null}
      </StyledSearchBox>
    </InstantSearch>
  );
};

const SearchModal = ({ element }) => {
  return (
    <StyledModal>
      <div>{element}</div>
    </StyledModal>
  );
};

export default SearchInputBox;
