// Material UI
import { Box, Typography, Button, Modal } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { styled } from "@mui/system";
import { PrimaryCTA } from "../UI_Components/PrimaryCTA";
import { useState } from "react";
import { handleContextSelection } from "../../redux/slices/commonSlice";
import { useDispatch, useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  boxShadow: 24,
  borderRadius: "16px",
  padding: "66px 24px 56px",
};

const ClearIcon = styled(ClearRoundedIcon)({
  cursor: "pointer",
  position: "absolute",
  top: 30,
  right: 30,
  color: "black",
});

const CTAContainer = styled(Box)({
  marginTop: "32px",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "1rem",
});

const Description = styled(Typography)({
  marginTop: "4px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "left",
});

const Title = styled(Typography)({
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "28px",
  letterSpacing: "0em",
  textAlign: "left",
  marginTop: "1rem",
});

const CTA = styled(Button)({
  textTransform: "unset",
  backgroundColor: "#D0D5DD",
  color: "black",
  "&:hover": {
    backgroundColor: "#D0D5DD",
    color: "black",
  },
});

// this card is specific to prompt builder interface
const ContextSelection = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const selectContext = (value) => {
    dispatch(
      handleContextSelection({
        value,
      })
    );
    onClose();
  };

  return (
    <Modal disableEnforceFocus open={open}>
      <Box sx={style}>
        <Box>
          <Title variant="h6" component="h2">
            Select your task context
          </Title>
          <Description>
            You want to create a task for text or document?
          </Description>
        </Box>
        <CTAContainer>
          <CTA variant="contained" onClick={() => selectContext("document")}>
            Document task
          </CTA>
          <PrimaryCTA
            variant="contained"
            color="error"
            ctaClick={() => selectContext("text")}
          >
            Text task
          </PrimaryCTA>
        </CTAContainer>
      </Box>
    </Modal>
  );
};

export default ContextSelection;
