// Material UI
import { Button } from "@mui/material";
import { styled } from "@mui/system";

// Static
import { primaryColor } from "../../static/staticStyles";

const StyledButton = styled(Button)({
  backgroundColor: primaryColor,
  color: "white",
  textTransform: "unset",
  width: "inherit",
  "&:hover": {
    backgroundColor: primaryColor,
  },
});

export const PrimaryCTA = ({ ctaClick, children, disabled = false }) => {
  return (
    <StyledButton variant="contained" disabled={disabled} onClick={ctaClick}>
      {children}
    </StyledButton>
  );
};
