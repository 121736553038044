import { useState } from "react";
import axios from "axios";

// Material UI
import { Box, Typography, Button, TextField } from "@mui/material";
import { styled } from "@mui/system";

// Local components
import OuterContainer from "../components/auth/OuterContainer";
import { MessageBox } from "../components/UIComponents/MessageBox";

// Helpers
import { validateEmail } from "../helpers/validateEmail";

// context
import AuthRedirect from "../components/auth/AuthRedirect";

const Label = styled("label")({
  fontSize: "1rem",
  fontWeight: 500,
  marginBottom: "14px",
  display: "block",
});

const Input = styled(TextField)(({ isinputvalid }) => ({
  width: "637px",
  marginBottom: "2rem",
  "& input": {
    padding: "12px",
    height: "22px",
    ...(isinputvalid
      ? {
          border: "2px solid #6aa272",
          backgroundColor: "#e4f2e6 !important",
          borderRadius: "inherit",
          height: "18px",
        }
      : {}),
  },
}));

const SubmitCTA = styled(Button)({
  backgroundColor: "#E8EEFE",
  color: "black",
  width: "371px",
  fontSize: "20px",
  fontWeight: 500,
  textTransform: "none",
  display: "block",
  margin: "auto",
});

const Heading = styled(Typography)({
  marginTop: "41px",
  fontSize: "24px",
  fontWeight: 500,
  textAlign: "center",
});

const FormContainer = styled(Box)({
  margin: "40px 102px 40px",
});

const ServerErrorDisplay = styled("div")({
  textAlign: "center",
  color: "#d32f2f",
});

function ForgotPassword() {
  const initialState = {
    value: "",
    error: "",
  };
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState(initialState);
  const [serverError, setserverError] = useState("");

  const isInputValid = () => {
    let isError = false;
    if (!validateEmail(email.value)) {
      isError = true;
      setEmail((val) => ({ ...val, error: "Incorrect email format" }));
    }
    if (email.value.trim().length === 0) {
      isError = true;
      setEmail((val) => ({ ...val, error: "Email cannot be empty" }));
    }
    return isError;
  };

  const confirmEmail = async () => {
    // API call
    try {
      // validation
      if (isInputValid()) return;
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/v1/auth/forgot-password`,
        { email: email.value }
      );
      if (response.status === 200) {
        setEmailSent(true);
      }
    } catch (error) {
      console.log(error);
      setserverError(error.response?.data?.message || error.response?.message);
    }
  };

  if (emailSent) {
    return (
      <MessageBox
        heading="Email sent!"
        content={
          <>
            You will receive an email in a few minutes at the following address:
            {email.value}.
            <br />
            <br />
            Please click on the link to reset your password.
          </>
        }
      />
    );
  }

  return (
    <OuterContainer>
      <Heading>Reset my password</Heading>
      <FormContainer component="form">
        <Label>Email address</Label>
        <Input
          error={!!email.error}
          helperText={email.error}
          required
          isinputvalid={validateEmail(email.value)}
          onChange={(event) => {
            setEmail({ error: "", value: event.target.value });
            setserverError("");
          }}
          placeholder="example@gmail.com"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              confirmEmail();
            }
          }}
        />
        <ServerErrorDisplay>{serverError}</ServerErrorDisplay>
        <br />
        <SubmitCTA
          disabled={!validateEmail(email.value)}
          onClick={confirmEmail}
        >
          Confirm my email
        </SubmitCTA>
      </FormContainer>
    </OuterContainer>
  );
}

const AuthPasswordForgot = () => (
  <AuthRedirect>
    <ForgotPassword />
  </AuthRedirect>
);

export default AuthPasswordForgot;
