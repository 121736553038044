// Material UI
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Panel = styled("div")({
  backgroundColor: "white",
  height: "200px",
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
  maxHeight: "100%",
  overflowY: "scroll",
  "& span": {
    backgroundColor: "rgba(0, 0, 0, 0.07)",
    padding: "0.3rem",
    borderRadius: "0.25rem",
    lineHeight: 2,
  },
});

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Panel role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Panel>
  );
}
