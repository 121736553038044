export const options = [
  {
    name: "Textarea",
    rows: 4,
    input_type: "text",
  },
  {
    name: "Input type text",
    rows: 1,
    input_type: "text",
  },
  {
    name: "Numbers",
    rows: false,
    input_type: "number",
  },
  {
    name: "true/false",
    rows: 1,
    input_type: "text",
  },
];

export const file_options = [
  {
    name: "Text File (csv, pdf, txt, json)",
    rows: "false",
    input_type: "file",
    // allowed_formats: ".csv,.txt,.json,.pdf",
    allowed_formats: ".pdf",
  },
  //   {
  //     name: "Image (jpeg, png)",
  //     rows: "false",
  //     input_type: "file",
  //     allowed_formats: ["jpeg", "png"],
  //   },
  //   {
  //     name: "Audio files (mp4, wav, aac)",
  //     rows: "false",
  //     input_type: "file",
  //     allowed_formats: ["mp4", "wav", "aac"],
  //   },
];
