import { useRef } from "react";

// Material UI
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

// Assets
import uploadicon from "../../assets/uploadicon.png";
import { primaryColor } from "../../static/staticStyles";

const InputBox = styled(Box)({
  width: "100%",
  height: "fit-content",
  padding: "16px 24px 16px 24px",
  borderRadius: "12px",
  border: "2px solid " + primaryColor,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
});

const Text = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  letterSpacing: "0em",
  textAlign: "center",
  "& span": {
    color: primaryColor,
    fontSize: "14px",
    fontWeight: 600,
  },
});

const UploadIcon = styled(Box)({
  padding: "12px 12px 6px",
  border: "1px solid #EAECF0",
  boxShadow: "0px 1px 2px 0px #1018280D",
  marginBottom: "12px",
  width: "fit-content",
  borderRadius: "8px",
});

const CustomFileInput = ({ onFileChange }) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    onFileChange(e);
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <InputBox onClick={handleClick}>
        <UploadIcon>
          <img src={uploadicon} />
        </UploadIcon>
        <Text>
          <span>Click to upload</span> or drag and drop
        </Text>
        <Text>SVG, PNG, JPG or GIF (max. 800x400px)</Text>
      </InputBox>
    </div>
  );
};

export default CustomFileInput;
