import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Cookies from "js-cookie";

// pages
import Layout from "./pages/Layout";
import AllPromptList from "./pages/AllPromptList";
import Register from "./pages/Register";
import Login from "./pages/Login";
import UserVerify from "./pages/UserVerify";
import Home from "./pages/Home";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Workspace from "./pages/Workspace";
import UsePrompt from "./pages/UsePrompt";
import AuthorizeUser from "./pages/AuthorizeUser";
import Welcome from "./pages/Welcome";

// HOC
import WithSidebar from "./components/sidebar/HOC/WithSidebar";

// Context
import { useAuth } from "./context/authContext";
import { Auth0ProviderWithNavigate } from "./context/auth0Provider";

// Material UI
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/material";

function App() {
  const { login, isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get("token");
    const user = Cookies.get("user");
    if (!!token) {
      login({ token, user, inExisting: true });
    }
    setLoading(false); // Set loading to false once authentication check is done
  }, []);

  // Show a loading indicator while checking authentication
  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Router>
      <Auth0ProviderWithNavigate>
        <Routes>
          {/* -------------------------- Authentication required ------------------------ */}
          <Route
            path="/add-prompt"
            element={isAuthenticated ? <Layout /> : <Navigate to="/" />}
          />
          <Route
            path="/all-prompts"
            element={isAuthenticated ? <AllPromptList /> : <Navigate to="/" />}
          />
          <Route
            path="workspace/:slug"
            element={
              <WithSidebar>
                <Workspace />
              </WithSidebar>
            }
          />

          {/* ---------------------------- For logged out user ---------------------------- */}
          <Route
            path="register"
            element={!isAuthenticated ? <Register /> : <Navigate to="/" />}
          />
          <Route
            path="login"
            element={!isAuthenticated ? <Login /> : <Navigate to="/" />}
          />
          <Route
            path="verify"
            element={!isAuthenticated ? <UserVerify /> : <Navigate to="/" />}
          />
          <Route
            path="forgot-password"
            element={
              !isAuthenticated ? <ForgotPassword /> : <Navigate to="/" />
            }
          />
          <Route
            path="reset-password"
            element={!isAuthenticated ? <ResetPassword /> : <Navigate to="/" />}
          />
          <Route
            path="authorize-user"
            element={!isAuthenticated ? <AuthorizeUser /> : <Navigate to="/" />}
          />

          {/* --------------------------------- Public pages -------------------------------- */}
          <Route path="/" element={!isAuthenticated ? <Welcome /> : <Home />} />
          <Route path="use-prompt/:id" element={<UsePrompt />} />
        </Routes>
      </Auth0ProviderWithNavigate>
    </Router>
  );
}

export default App;
