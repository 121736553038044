// Material UI
import { Box, Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";

// Static
import { file_options, options } from "../../../static/addFieldOptions";
import { useSelector } from "react-redux";

const FieldsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  marginTop: "1rem",
});

const CTA = styled(Button)({
  color: "white",
  backgroundColor: "#9E77ED",
  textTransform: "unset",
  "&:hover": {
    color: "white",
    backgroundColor: "#9E77ED",
  },
});

function AddFields({ handledisplayModal }) {
  const context_is_files =
    useSelector((state) => state.common.context_Selection_modal.value) ===
    "document";
  const available_fields = [
    ...options,
    ...(context_is_files ? file_options : []),
  ];
  const handleAddField = (values) => {
    handledisplayModal(values);
  };

  return (
    <Box>
      <FieldsContainer>
        {available_fields.map((option, index) => (
          <CTA
            key={index}
            onClick={() => handleAddField(option)}
            variant="contained"
          >
            <AddIcon /> {option.name}
          </CTA>
        ))}
      </FieldsContainer>
    </Box>
  );
}

export default AddFields;
