// Material UI
import { Typography } from "@mui/material";
import styled from "@emotion/styled";

// Local components
import OuterContainer from "../auth/OuterContainer";

const VerificationContainer = styled("div")({
  padding: "88px 118px",
  width: "610px",
});

const VerificationTitle = styled(Typography)({
  fontSize: "24px",
  fontWeight: 500,
  marginBottom: "56px",
});

const VerificationMessage = styled(Typography)({
  fontSize: "20px",
  fontWeight: 300,
});

export const MessageBox = ({ heading, content }) => {
  return (
    <OuterContainer>
      <VerificationContainer>
        <VerificationTitle>{heading}</VerificationTitle>
        <VerificationMessage>{content}</VerificationMessage>
      </VerificationContainer>
    </OuterContainer>
  );
};
