import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

// Material UI
import { Box, Button, Typography } from "@mui/material";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { styled } from "@mui/system";

// Redux
import { handleSettingDisplay } from "../../../redux/slices/settingSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  handleTitleChange,
  handleSavePromptDisplay,
  handlePromptData,
  handleDescChange,
} from "../../../redux/slices/savePromptSlice";

// Hooks
import { useCursorPositionAtEnd } from "../../../helpers/hooks/useCursorPositionAtEnd";

// Helpers
import { handleAutosave } from "../../../helpers/API_calls/handleAutosave";

// Context
import { useAuth } from "../../../context/authContext";

const CTA = styled(Button)({
  width: "100%",
  color: "black",
  backgroundColor: "rgba(0, 0, 0, 0.37)",
  "&:hover": {
    color: "black",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
  },
});

const CTAContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1rem",
  gap: "1rem",
});

const SaveCTA = styled(Button)({
  width: "100%",
  color: "black",
  backgroundColor: "rgba(0, 0, 0, 0.37)",
  margin: "1rem 1rem 0 auto",
  "&:hover": {
    color: "black",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
  },
});

const EditIcon = styled(CreateRoundedIcon)({
  cursor: "pointer",
  color: "#1976d2",
  width: "20px",
  height: "20px",
});

const DoneIcon = styled(DoneRoundedIcon)({
  cursor: "pointer",
  color: "#1976d2",
  width: "20px",
  height: "20px",
});

const Title = styled(Typography)({
  width: "fit-content",
  fontSize: "30px",
  fontWeight: 600,
  lineHeight: "38px",
  letterSpacing: "0em",
  textAlign: "left",
  "&:focus": {
    outline: "none",
  },
});

const PromptDataContainer = styled("div")({
  padding: "2rem",
});

const TitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  whiteSpace: "nowrap",
});

const Description = styled(Typography)({
  fontSize: "16px",
  width: "fit-content",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "left",
  color: "#475467",
  "&:focus": {
    outline: "none",
  },
});

function PromptData() {
  const dispatch = useDispatch();

  // -------------- importing state to autosave ---------------------
  const { title, description } = useSelector((state) => state.save_prompt);

  const handleSaveID = (tempID) => {
    dispatch(handlePromptData({ id: tempID }));
  };

  // --------------- end of importing state to autosave ---------------

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [isDescEditing, setIsDescEditing] = useState(false);

  useCursorPositionAtEnd(titleRef, isTitleEditing, !isTitleEditing);

  const handleLabelUpdate = (event) => {
    dispatch(
      handleTitleChange({
        title: event.target.innerText,
      })
    );
  };

  const handleDescUpdate = (event) => {
    dispatch(
      handleDescChange({
        description: event.target.innerText,
      })
    );
  };

  // const autosaveOnClick = () => {
  //   handleAutosave({
  //     query,
  //     promptID,
  //     title,
  //     description,
  //     max_tokens,
  //     temperature,
  //     modal,
  //     fields,
  //     status,
  //     context,
  //     handleSaveID,
  //     UserId: user.id,
  //   });
  // };

  return (
    <PromptDataContainer>
      <TitleBox isEditing={isTitleEditing}>
        <Title
          variant="h4"
          contentEditable={isTitleEditing}
          onBlur={handleLabelUpdate}
          onDoubleClick={() => setIsTitleEditing(true)}
          ref={titleRef}
          onKeyDown={(event) => {
            if (event.target.innerText.length > 35) {
              event.preventDefault();
            }
          }}
        >
          {title}
        </Title>
        {isTitleEditing ? (
          <DoneIcon
            onClick={() => {
              setIsTitleEditing(false);
            }}
          />
        ) : null}
      </TitleBox>
      <TitleBox>
        <Description
          ref={descriptionRef}
          contentEditable={isDescEditing}
          onBlur={handleDescUpdate}
          onDoubleClick={() => setIsDescEditing(true)}
          onKeyDown={(event) => {
            if (event.target.innerText.length > 400) {
              event.preventDefault();
            }
          }}
        >
          {description || "Give a title to your task"}
        </Description>
        {isDescEditing ? (
          <DoneIcon
            onClick={() => {
              setIsDescEditing(false);
            }}
          />
        ) : null}
      </TitleBox>
    </PromptDataContainer>
  );
}

export default PromptData;
