// Material UI
import {
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Assets
import platformLogo from "../../src/assets/platformLogo.png";
import featureIcon1 from "../../src/assets/featureIcon1.png";
import featureIcon2 from "../../src/assets/featureIcon2.png";
import featureIcon3 from "../../src/assets/featureIcon3.png";
import contentImage from "../../src/assets/contentImage.png";
import partner1 from "../../src/assets/partner1.png";
import partner2 from "../../src/assets/partner2.png";
import partner3 from "../../src/assets/partner3.png";
import partner4 from "../../src/assets/partner4.png";
import partner5 from "../../src/assets/partner5.png";

// Local components
import { PrimaryCTA } from "../components_v2/UI_Components/PrimaryCTA";
import Menubar from "../components_v2/landingPage/Menubar.mv";

// Context
import { useAuth0 } from "@auth0/auth0-react";

// Static
import { primaryColor } from "../static/staticStyles";

const standardPadding_desktop = "0px 112px";
const standardPadding_mobile = "0px 16px";

const RootContainer = styled(Box)({
  minHeight: "100vh",
});

const TopSection = styled(Box)(({ theme }) => ({
  padding: standardPadding_desktop,
  backgroundColor: "#0C111D",
  height: "100vh",
  color: "white",
  [theme.breakpoints.down("sm")]: {
    padding: standardPadding_mobile,
  },
}));

const Navbar = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "24px 0px",
});

const IconBoxNavbar = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

const CTABoxNavbar = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px",
});

const SmallTextHeading = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    textAlign: "left",
  },
}));

const LargeHeading = styled(Typography)(({ theme }) => ({
  fontSize: "72px",
  fontWeight: 600,
  lineHeight: "90px",
  letterSpacing: "-0.02em",
  textAlign: "center",
  maxWidth: "768px",
  margin: "12px auto 24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "36px",
    lineHeight: "44px",
    textAlign: "left",
  },
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "30px",
  letterSpacing: "0em",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    textAlign: "left",
  },
}));

const MiddleSection = styled(Box)(({ theme }) => ({
  padding: standardPadding_desktop,
  [theme.breakpoints.down("sm")]: {
    padding: standardPadding_mobile,
  },
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: "64px 112px 48px",
  backgroundColor: "#F9FAFB",
  [theme.breakpoints.down("sm")]: {
    padding: "64px 16px 48px",
  },
}));

const IconBoxFooter = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  marginBottom: "64px",
});

const list = [
  {
    icon: featureIcon1,
    title: "Unlimited cards",
    description:
      "Give your team the autonomy they need with access to as many cards as they need. Authorise purchases with a click. Simple.",
    link: "",
  },
  {
    icon: featureIcon2,
    title: "Easy expense policies",
    description:
      "Every card comes with configurable spending limits, purchase restrictions, and cancellations for each employee and team.",
    link: "",
  },
  {
    icon: featureIcon3,
    title: "Advanced analytics",
    description:
      "An all-in-one platform that helps you balance everything your team need to be happy and your finances in order.",
    link: "",
  },
];

const partners = [partner1, partner2, partner3, partner4, partner5];

const Welcome = () => {
  const small_screen = useMediaQuery("(max-width:1300px)");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { loginWithRedirect } = useAuth0();

  const handleAuthClick = () => {
    loginWithRedirect();
  };

  return (
    <RootContainer>
      <TopSection>
        <Navbar>
          <IconBoxNavbar>
            <img src={platformLogo} />
            <span>Kontoro</span>
          </IconBoxNavbar>
          {!isMobile ? (
            <CTABoxNavbar>
              <Button
                variant="text"
                sx={{ color: "white", textTransform: "unset" }}
                onClick={handleAuthClick}
              >
                Log in
              </Button>
              <PrimaryCTA ctaClick={handleAuthClick}>Sign up</PrimaryCTA>
            </CTABoxNavbar>
          ) : (
            <Menubar>
              <Box
                style={{
                  height: "50%",
                  display: "flex",
                  gap: "2rem",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  padding: "2rem",
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color: primaryColor,
                    textTransform: "unset",
                    width: "100%",
                  }}
                  onClick={handleAuthClick}
                >
                  Log in
                </Button>
                <PrimaryCTA ctaClick={handleAuthClick}>Sign up</PrimaryCTA>
              </Box>
            </Menubar>
          )}
        </Navbar>

        <Box
          sx={{
            marginTop: "96px",
          }}
        >
          <SmallTextHeading>Super. Simple. Working.</SmallTextHeading>
          <LargeHeading>Simple banking that works like magic.</LargeHeading>
          <SubHeading>
            Simple, transparent banking. No hidden fees and free overdrafts.
          </SubHeading>
        </Box>
      </TopSection>
      <MiddleSection>
        <Box
          sx={{
            padding: "96px 0px",
          }}
        >
          <Box sx={{ marginBottom: "64px" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#6941C6",
              }}
            >
              Features
            </Typography>

            <Typography
              sx={{
                fontSize: "36px",
                fontWeight: 600,
                lineHeight: "44px",
                letterSpacing: "-0.02em",
                textAlign: "left",
                margin: "12px 0px 20px",
              }}
            >
              The only platform you’ll ever need. Simple.
            </Typography>

            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "30px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Spend lower
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "64px",
              ...(small_screen ? { marginBottom: "64px" } : {}),
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "48px",
                flex: 1,
              }}
            >
              {list.map((val, i) => (
                <FeaturesList {...val} key={i} />
              ))}
            </Box>
            {!isMobile ? (
              <Box>
                <img
                  style={{
                    marginRight: "-112px",
                    ...(small_screen ? { width: "500px" } : {}),
                  }}
                  src={contentImage}
                />
              </Box>
            ) : null}
          </Box>

          <Box
            sx={{
              padding: isMobile ? "64px 24px" : "64px",
              backgroundColor: "#F9FAFB",
              borderRadius: "16px",
              marginBottom: "96px",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 400,
                letterSpacing: "0em",
                textAlign: "center",
                color: "#475467",
                marginBottom: "32px",
              }}
            >
              Our Major Partners
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: "1rem",
              }}
            >
              {partners.map((icon, i) => (
                <img
                  src={icon}
                  key={i}
                  style={
                    small_screen ? { width: isMobile ? "125px" : "130px" } : {}
                  }
                />
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: primaryColor,
              color: "white",
              padding: isMobile ? "40px 24px" : "64px",
              borderRadius: "16px",
              marginBottom: "96px",
              display: "flex",
              gap: "30px",
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "30px",
                  fontWeight: 600,
                  lineHeight: "38px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  marginBottom: "16px",
                }}
              >
                Start your 30-day free trial
              </Typography>

              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "30px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                Join over 4,000+ startups already growing with Kontoro.ia.
              </Typography>
            </Box>
            <Button
              sx={{
                color: "black",
                backgroundColor: "white",
                height: "fit-content",
                textTransform: "unset",
              }}
            >
              Discover the power of IA for free
            </Button>
          </Box>
        </Box>
      </MiddleSection>

      <Footer>
        <IconBoxFooter>
          <img src={platformLogo} />
          <span>Kontoro</span>
        </IconBoxFooter>

        <Box
          sx={{
            paddingTop: "32px",
            borderTop: "1px solid #EAECF0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#667085",
            }}
          >
            © 2077 Untitled UI. All rights reserved.
          </Typography>
          <Box sx={{ display: "flex", gap: "16px" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#667085",
              }}
            >
              Terms
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#667085",
              }}
            >
              Privacy
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#667085",
              }}
            >
              Cookies
            </Typography>
          </Box>
        </Box>
      </Footer>
    </RootContainer>
  );
};

const FeaturesList = ({ title, icon, description, link }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
      }}
    >
      <Box>
        <img src={icon} />
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "30px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0em",
            textAlign: "left",
            margin: "8px 0px 20px",
          }}
        >
          {description}
        </Typography>
        <Button
          variant="text"
          sx={{
            color: "#6941C6",
            textTransform: "unset",
            padding: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          Learn more <ArrowForwardIcon sx={{ color: "#6941C6" }} />
        </Button>
      </Box>
    </Box>
  );
};

export default Welcome;
