import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuIcon from "@mui/icons-material/Menu";

const Root = styled("div")({
  height: "100%",
  backgroundColor: "transparent",
});

function Menubar({ children }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Root>
      <Box sx={{ textAlign: "center", pt: 1 }}>
        <MenuIcon
          sx={{
            color: "white",
            cursor: "pointer",
          }}
          onClick={toggleDrawer(true)}
        />
      </Box>
      <SwipeableDrawer
        container={container}
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "80%",
          },
        }}
      >
        {children}
      </SwipeableDrawer>
    </Root>
  );
}

export default Menubar;
