export const extractTextContent = (inputString) => {
  const html = `<div>${inputString}</div>`;
  const element = document.createElement("div");
  element.innerHTML = html;
  const text = element.innerText;
  return text;
};

export const updateSpanText = (string, array) => {
  const regex = /(<span data-id=)(\d+)>([^<]+)<\/span>/g;

  const updatedString = string.replace(regex, (match, p1, p2, p3) => {
    const dataId = array.findIndex((val) => val.id === +p2);
    if (array[dataId]?.value !== undefined) {
      return p1 + dataId + ">" + array[dataId].value + "</span>";
    }
    return match;
  });

  return updatedString;
};

export const removeSpanByIndex = (inputString, index) => {
  const regex = new RegExp(`<span data-id=${index}>[^<]*<\/span>`, "g");
  return inputString.replace(regex, "");
};
