import { configureStore } from "@reduxjs/toolkit";
import queryReducer from "./slices/querySlice";
import settingReducer from "./slices/settingSlice";
import savePromptReducer from "./slices/savePromptSlice";
import commonReducer from "./slices/commonSlice";
import withsidebarReducer from "./slices/withsidebarSlice";

export default configureStore({
  reducer: {
    query: queryReducer,
    setting: settingReducer,
    save_prompt: savePromptReducer,
    common: commonReducer,
    withsidebar: withsidebarReducer,
  },
});
