import { useEffect } from "react";

export const useCursorPositionAtEnd = (
  ref,
  dependency,
  preventExecution = false
) => {
  /**
   * positions the cursor at the end of thne ref container
   */
  useEffect(() => {
    if (preventExecution) return;
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(ref.current);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }, [dependency]);
};
