// src/components/AuthRedirect.js
import React from "react";
import { Navigate } from "react-router-dom";

// Context
import { useAuth } from "../../context/authContext";

function AuthRedirect({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/home" />;
  }

  return children;
}

export default AuthRedirect;
