// Material UI
import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Wrapper = styled("div")({
  height: "100vh",
  backgroundColor: "#E8EEFE",
  display: "grid",
  placeItems: "center",
});

const Logo = styled(Typography)({
  position: "absolute",
  top: -70,
  right: "50%",
  fontSize: "36px",
  fontWeight: 900,
});

function OuterContainer({ children }) {
  return (
    <Wrapper>
      <Paper
        sx={{ minHeight: "100px", minWidth: "200px", position: "relative" }}
        elevation={1}
      >
        {children}
        <Logo>K</Logo>
      </Paper>
    </Wrapper>
  );
}

export default OuterContainer;
