import React, { useEffect, useState } from "react";
import axios from "axios";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, Hits, Configure } from "react-instantsearch";
import { toast } from "react-toastify";

// Material UI
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";

// Local components
import PromptsOnWorkspace from "./PromptsOnWorkspace";
import PromptsToSelect from "./PromptsToSelect";
import Pagination from "../../../components_v2/UI_Components/Pagination";
import SearchModal from "./SearchModal";

// Context
import { useAuth } from "../../../context/authContext";

// Static
import { hideScrollbar } from "../../../static/staticStyles";

const StyledSelectPromptContainer = styled(Box)({
  height: "100%",
  overflow: "clip",
  borderRadius: "10px",
  position: "relative",
  margin: "0px 16px",
});

const StyledTopContainer = styled(Box)({
  position: "sticky",
  top: 0,
  width: "100%",
  zIndex: 10,
});

const StyledRHS = styled(Box)({
  overflowY: "auto",
  ...hideScrollbar,
  height: "calc(100vh - 187px)",
  width: "250px",
  marginTop: "40px",
});

const StyledBottomSection = styled(Grid)({
  height: "100%",
  position: "sticky",
  top: 0,
  overflowY: "auto",
  ...hideScrollbar,
});

const StyledSearchBox = styled(Box)({
  width: "100%",
  position: "relative",
  "& li": {
    padding: "0.5rem",
  },
});

const RootContainer = styled("div")({
  backgroundColor: "#EDEDF0",
  borderRadius: "30px",
  padding: "1rem",
  marginTop: "40px",
  height: "calc(100vh - 171px)",
  flex: 1,
  position: "relative",
});

const PaginationSection = styled(Box)({
  position: "absolute",
  bottom: 0,
  width: "100%",
  backgroundColor: "#EDEDF0",
  borderBottomLeftRadius: "16px",
  borderBottomRightRadius: "16px",
  marginLeft: "-15px",
});

const SelectPrompt = ({ slug, existingPrompts }) => {
  const { user } = useAuth();

  const [timer, setTimer] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [searchModalDisplay, setSearchModalDisplay] = useState(false);

  const [selectedPrompts, setSelectedPrompts] = useState([]);
  const [paginationState, setPaginationState] = useState({
    hasMore: null,
    page: 1,
  });

  const [promptsToSelect, setPromptsToSelect] = useState([]);
  const [state, setState] = useState({
    page: 1,
    totalPages: 1,
  });

  const searchClient = algoliasearch(
    "W6NVMYO3C8",
    "2c6dcfb441e47528aa376f015a5a58ab"
  );

  async function fetchPromptsToSelect(query) {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_PORT
        }/v1/prompt/all?page=${1}&limit=8&type=published&skip_workspace=${slug}${
          query ? `&search_query=${query}` : ``
        }`
      );
      if (response.data.result.success) {
        setPromptsToSelect(response.data.result.data);
        setState((val) => ({
          ...val,
          totalPages: response.data.result.pages,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchPromptsToSelect();
    const selected_prompts = existingPrompts;
    setSelectedPrompts(selected_prompts);
    setPaginationState({ hasMore: existingPrompts.length > 4, page: 1 });
  }, [slug]);

  const selectPrompts = async (clickedPrompt) => {
    setSearchModalDisplay(false);
    const updatedPrompts = promptsToSelect.filter(
      (prompt) => prompt.id !== clickedPrompt.id
    );
    setPromptsToSelect(updatedPrompts);
    if (selectedPrompts.findIndex(({ id }) => id === clickedPrompt.id) > -1) {
      return;
    }
    setSelectedPrompts((val) => [clickedPrompt, ...val]);
    setPaginationState((val) => ({
      ...val,
      hasMore: selectedPrompts.length >= 4,
    }));
    try {
      await axios.post(
        `${process.env.REACT_APP_PORT}/v1/workspace/${clickedPrompt.id}/add?slug=${slug}`
      );
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong.", {
        position: "bottom-center",
        hideProgressBar: true,
        theme: "light",
      });
    }
  };

  const unselectPrompt = async (clickedPrompt) => {
    const updatedPrompts = selectedPrompts.filter(
      (prompt) => prompt.id !== clickedPrompt.id
    );
    setSelectedPrompts(updatedPrompts);
    setPromptsToSelect((val) => [...val, clickedPrompt]);
    // api call to remove prompts
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_PORT}/v1/workspace/${clickedPrompt.id}/delete?slug=${slug}`
      );
      if (res.data.status === 200) {
        // Handle success - api call for selected prompts for current page, if for current page no prompts are available the get prompts of pervious page - keep doing this until there are no selected prompts left

        const response = await axios.get(
          `${process.env.REACT_APP_PORT}/v1/workspace/${slug}?userId=${user.id}&page=${paginationState.page}&limit=10`
        );
        if (response.data.status === 200) {
          if (response.data.result.prompts.length === 0) {
            prevSelectedPrompts();
          } else {
            setSelectedPrompts(response.data.result.prompts);
          }
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong.", {
        position: "bottom-center",
        hideProgressBar: true,
        theme: "light",
      });
    }
  };

  const prevSelectedPrompts = async () => {
    if (paginationState.page < 2) {
      // show error
      return;
    }
    try {
      const prevPage = paginationState.page - 1;
      setPaginationState({ hasMore: true, page: prevPage });
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/v1/workspace/${slug}?userId=${user.id}&page=${prevPage}&limit=10`
      );
      if (response.data.status === 200) {
        setSelectedPrompts(response.data.result.prompts);
      }
    } catch (error) {
      console.log(error);
      setPaginationState((val) => ({ ...val, page: paginationState.page - 1 }));
    }
  };

  const getNewPrompts = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/v1/prompt/all?page=${page}&limit=8&type=published&skip_workspace=${slug}`
      );
      if (response.data.result.success) {
        setPromptsToSelect(response.data.result.data);
        setState({
          page,
          totalPages: response.data.result.pages,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchEnter = () => {
    fetchPromptsToSelect(searchInput);
    setSearchModalDisplay(false);
  };

  const handleSearchQuery = (query, search) => {
    setSearchInput(query);
    setSearchModalDisplay(!!query);
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        if (query.trim()) {
          search(query);
        }
      }, 1500)
    );
  };

  const handleHits = (hit) => {
    setSearchInput(hit.title);
    selectPrompts({ id: hit.objectID, ...hit });
    handleSearchEnter();
  };

  return (
    <StyledSelectPromptContainer elevation={3}>
      <InstantSearch searchClient={searchClient} indexName="prompts" insights>
        <Configure hitsPerPage={6} />
        <Box sx={{ display: "flex", gap: "40px" }}>
          <RootContainer>
            <StyledTopContainer>
              <Box sx={{ position: "relative" }}>
                <StyledSearchBox>
                  <SearchBox
                    placeholder="Search a prompt"
                    queryHook={handleSearchQuery}
                    onSubmit={handleSearchEnter}
                  />
                  {searchInput?.trim() && searchModalDisplay ? (
                    <SearchModal
                      element={
                        <Hits
                          hitComponent={({ hit }) => (
                            <div
                              onClick={() => handleHits(hit)}
                              style={{ cursor: "pointer", width: "100%" }}
                            >
                              {hit.title}
                            </div>
                          )}
                        />
                      }
                    />
                  ) : null}
                </StyledSearchBox>
              </Box>
            </StyledTopContainer>

            <StyledBottomSection container spacing={2}>
              <PromptsToSelect
                promptsToSelect={promptsToSelect}
                state={state}
                getNewPrompts={getNewPrompts}
                selectPrompts={selectPrompts}
              />
            </StyledBottomSection>

            <PaginationSection>
              <Pagination
                totalPages={state.totalPages}
                currPage={state.page}
                onNextClick={() => getNewPrompts(state.page + 1)}
                onPrevClick={() => getNewPrompts(state.page - 1)}
              />
            </PaginationSection>
          </RootContainer>
          {selectedPrompts?.length > 0 && (
            <StyledRHS>
              <PromptsOnWorkspace
                selectedPrompts={selectedPrompts}
                unselectPrompt={unselectPrompt}
              />
            </StyledRHS>
          )}
        </Box>
      </InstantSearch>
    </StyledSelectPromptContainer>
  );
};

export default SelectPrompt;
