import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

// Material UI
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";

// Local Components
import WithSidebar from "../components/sidebar/HOC/WithSidebar";
import GetStarted from "../components_v2/UI_Components/GetStarted";
import Pagination from "../components_v2/UI_Components/Pagination";
import EditModal from "../components_v2/EditModal";
import AllTasksLists from "../components_v2/AllTasksLists";
import PromptUpdateModal from "../components_v2/PromptUpdateModal";
import { PrimaryCTA } from "../components_v2/UI_Components/PrimaryCTA";

// Context
import { useAuth } from "../context/authContext";

// Assets
import customDelete from "../assets/customDelete.png";
import { hideScrollbar } from "../static/staticStyles";

const RootContainer = styled("div")({
  height: "calc(100vh - 32px)",
  position: "relative",
  flexBasis: "calc(100vw - 134.547px)",
});

const RHSHeader = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  gap: "1rem",
  marginBottom: "30px",
  padding: "32px",
  justifyContent: "space-between",
});

const RHSCardSection = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
});

const BottomSection = styled(Box)({
  bottom: 0,
});

const AddPrompts = styled(Box)({
  border: "2px solid black",
  height: "233px",
  width: "100%",
  borderRadius: "10px",
  display: "grid",
  placeItems: "center",
  cursor: "pointer",
});

function AllPromptList() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [prompts, setPrompts] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [filterVal, setFilterVal] = useState("all_prompts");
  const [currPage, setCurrPage] = useState(1);

  // state for modals
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState({
    id: null,
    title: "",
    description: "",
  });
  const [editModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    getPrompts(1, "all_prompts");
  }, []);

  const getPrompts = async (page, filter) => {
    try {
      const limit = 6;
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/v1/prompt/${user.id}/user?page=${page}&limit=${limit}&type=${filter}`
      );
      if (response.data.result.success) {
        setPrompts(response.data.result.data);
        setTotalPages(response.data.result.pages);
        setCurrPage(page);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlePagination = (page) => {
    getPrompts(page, filterVal);
  };

  // const handleFilterChange = (val) => {
  //   setFilterVal(val);
  //   getPrompts(1, val);
  // };

  const handleDeleteClick = (id) => {
    setModalOpen(true);
    setSelectedPrompt((val) => ({ ...val, id }));
  };

  const handlePromptDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_PORT}/v1/prompt/${selectedPrompt.id}/delete`
      );
      if (response.data.result.success) {
        toast.success("Prompt deleted successfully!");
        handlePagination(currPage);
        setModalOpen(false);
      }
    } catch (error) {
      toast.error("Something wrong happned. Try again.");
      console.log(error);
    }
  };

  const handleEdit = async ({ id, title, description }) => {
    // fetch data from slices
    const response = await axios.post(
      `${process.env.REACT_APP_PORT}/v1/prompt/edit`,
      {
        id,
        title,
        description,
        UserId: user.id,
      }
    );
    if (response.data?.result?.success) {
      toast.success("Prompt edited successfully!");
      setEditModalOpen(false);
      setPrompts((val) =>
        val.map((prompt) =>
          prompt.id === id ? { ...prompt, title, description } : prompt
        )
      );
    }
  };

  return (
    <WithSidebar>
      <RootContainer>
        <RHSHeader>
          <Box>
            <Typography sx={{ fontSize: "28px" }} variant="h3">
              All Tasks
            </Typography>
            <Typography>View all yours tasks and their status</Typography>
          </Box>
          <PrimaryCTA
            ctaClick={() => {
              navigate("/add-prompt");
            }}
          >
            <AddIcon sx={{ height: 20, width: 20, marginRight: "0.5rem" }} />{" "}
            Create task
          </PrimaryCTA>
          {/* <Dropdown
            list={[
              { value: "all_prompts", display_name: "All Prompts" },
              { value: "published", display_name: "Published" },
              { value: "in_progress", display_name: "In Progress" },
            ]}
            data={filterVal}
            onChange={handleFilterChange}
          /> */}
        </RHSHeader>

        {prompts?.length > 0 ? (
          <div
            style={{
              borderRadius: "16px",
              border: "1px solid #EAECF0",
              height: "calc(100vh - 216px)",
              margin: "0px 32px",
            }}
          >
            <RHSCardSection>
              <Box
                sx={{
                  height: "calc(100% - 72px)",
                  overflowY: "auto",
                  ...hideScrollbar,
                }}
              >
                <AllTasksLists
                  prompts={prompts}
                  handlePagination={handlePagination}
                  currPage={currPage}
                  onDelete={handleDeleteClick}
                  onEdit={(prompt) => {
                    setEditModalOpen(true);
                    setSelectedPrompt(prompt);
                  }}
                />
              </Box>
              {prompts?.length > 0 && (
                <BottomSection>
                  <Pagination
                    totalPages={totalPages}
                    currPage={currPage}
                    onNextClick={() => handlePagination(currPage + 1)}
                    onPrevClick={() => handlePagination(currPage - 1)}
                  />
                </BottomSection>
              )}
            </RHSCardSection>
          </div>
        ) : null}
        {!prompts || prompts?.length == 0 ? (
          <GetStarted
            heading="Create your first task"
            subHeading="Create your own task and use it on yours workspaces"
            handleClick={() => navigate("/add-prompt")}
          />
        ) : null}
      </RootContainer>
      <PromptUpdateModal
        icon={customDelete}
        heading="Delete task"
        description="Are you sure you want to delete this task? This action cannot be undone."
        ctaText="Delete"
        onCTAClick={handlePromptDelete}
        handleClose={() => setModalOpen(false)}
        open={modalOpen}
      />
      <EditModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        handleEdit={handleEdit}
        provided_title={selectedPrompt.title}
        provided_description={selectedPrompt.description}
        id={selectedPrompt.id}
      />
    </WithSidebar>
  );
}

export default AllPromptList;
