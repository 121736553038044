import { useState } from "react";
import parse from "html-react-parser";

// Material UI
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { hideScrollbar } from "../../../static/staticStyles";

const Panel = styled("div")({
  backgroundColor: "white",
  height: "fit-content",
  minHeight: "200px",
  maxHeight: "500px",
  overflowY: "auto",
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
  "& span": {
    backgroundColor: "rgba(0, 0, 0, 0.07)",
    padding: "0.3rem",
    borderRadius: "0.25rem",
    lineHeight: 2,
  },
  "& table, th, td": {
    border: "1px solid black",
  },
  ...hideScrollbar,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Panel role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Panel>
  );
}

export default function ModalAnswerTabs({ name, text, data }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
          <Tab label={name} />
          {/* <Tab label="JSON" /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {parse(text)}
      </TabPanel>
    </Box>
  );
}
