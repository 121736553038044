import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

// Material UI
import { Box, Typography, Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LinearProgress from "@mui/material/LinearProgress";

// Local components
import OuterContainer from "../components/auth/OuterContainer";
import { MessageBox } from "../components/UIComponents/MessageBox";

// Helpers
import { validatePassword } from "../helpers/validatePassword";

// context
import AuthRedirect from "../components/auth/AuthRedirect";

const Label = styled("label")({
  fontSize: "1rem",
  fontWeight: 500,
  marginBottom: "14px",
  display: "block",
});

const Input = styled(TextField)(({ isinputvalid }) => ({
  width: "637px",
  marginBottom: "2rem",
  "& input": {
    padding: "12px",
    height: "22px",
    ...(isinputvalid
      ? {
          border: "2px solid #6aa272",
          backgroundColor: "#e4f2e6 !important",
          borderRadius: "inherit",
          height: "18px",
        }
      : {}),
  },
}));

const SubmitCTA = styled(Button)({
  backgroundColor: "#E8EEFE",
  color: "black",
  width: "371px",
  fontSize: "20px",
  fontWeight: 500,
  textTransform: "none",
  display: "block",
  margin: "auto",
});

const Heading = styled(Typography)({
  marginTop: "41px",
  fontSize: "24px",
  fontWeight: 500,
  textAlign: "center",
});

const FormContainer = styled(Box)({
  margin: "40px 102px 40px",
});

const IconBox = styled("span")({
  cursor: "pointer",
  position: "absolute",
  right: 10,
  top: 10,
});

function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const accessID = location.search.split("id=")[1];
  const initialState = {
    value: "",
    error: "",
    isPassword: true,
  };
  const [loading, setLoading] = useState(true);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [password, setPassword] = useState(initialState);
  const [content, setContent] = useState({
    heading: "Password reset!",
    para: (
      <>
        Your password has been successfully reset.
        <br />
        <br />
        You will now be redirected to the login page.
      </>
    ),
  });

  useEffect(() => {
    if (!!location.search) {
      (async () => {
        try {
          // extract id from location
          const response = await axios.post(
            `${process.env.REACT_APP_PORT}/v1/auth/verify/${accessID}`
          );
          if (response?.status === 200) {
            setLoading(false);
          }
        } catch (error) {
          toast.error("Something went wrong!", {
            position: "top-center",
            hideProgressBar: true,
            theme: "light",
          });
          setTimeout(() => navigate("/home"), 2000);
          console.log("error response ", error.response);
        }
      })();
    }
  }, []);

  const isInputValid = () => {
    let isError = false;
    if (!validatePassword(password.value)) {
      isError = true;
      setPassword((val) => ({ ...val, error: "Incorrect password format" }));
    }
    if (password.value.trim().length === 0) {
      isError = true;
      setPassword((val) => ({ ...val, error: "Email cannot be empty" }));
    }
    return isError;
  };

  const updatePassword = async () => {
    try {
      if (isInputValid()) return;
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/v1/auth/confirm-password`,
        { password: password.value, accessID }
      );
      if (response.status === 200) {
        setPasswordChanged(true);
        setTimeout(() => navigate("/login"), 3000);
      }
    } catch (error) {
      setContent({ heading: "", para: "Something went wrong!" });
      console.log(error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  if (passwordChanged) {
    return <MessageBox heading={content.heading} content={content.para} />;
  }

  return (
    <OuterContainer>
      <Heading>Reset my password</Heading>
      <FormContainer component="form">
        <Label>New Password</Label>
        <div style={{ position: "relative" }}>
          <Input
            error={!!password.error}
            type={password.isPassword ? "password" : "text"}
            helperText={password.error}
            required
            isinputvalid={validatePassword(password.value)}
            onChange={(event) => {
              setPassword((val) => ({
                ...val,
                error: "",
                value: event.target.value,
              }));
            }}
            placeholder="Password"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                updatePassword();
              }
            }}
          />
          <IconBox
            onClick={() =>
              setPassword((val) => ({
                ...val,
                isPassword: !password.isPassword,
              }))
            }
          >
            {password.isPassword ? (
              <VisibilityIcon color="action" />
            ) : (
              <VisibilityOffIcon color="action" />
            )}
          </IconBox>
        </div>
        <SubmitCTA
          disabled={!validatePassword(password.value)}
          onClick={updatePassword}
        >
          Confirm
        </SubmitCTA>
      </FormContainer>
    </OuterContainer>
  );
}

const AuthPasswordReset = () => (
  <AuthRedirect>
    <ResetPassword />
  </AuthRedirect>
);

export default AuthPasswordReset;
