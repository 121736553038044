import axios from "axios";
import { useNavigate } from "react-router-dom";

// Material UI
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

// Redux
import { useDispatch } from "react-redux";
import { handlePromptData } from "../redux/slices/savePromptSlice";
import { updateQueryState } from "../redux/slices/querySlice";
import { handleContext } from "../redux/slices/commonSlice";
import { updateSettings } from "../redux/slices/settingSlice";

const StyledTable = styled("table")({
  borderCollapse: "collapse",
  width: "100%",
  "& tr": {
    borderBottom: "1px solid #EAECF0",
    borderCollapse: "collapse",
  },
  borderRadius: "16px",
  "& th": {
    textAlign: "left",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    padding: "8px 16px",
  },
});

const StatusDot = styled("div")(({ backgroundColor }) => ({
  width: "6px",
  height: "6px",
  borderRadius: "50%",
  fontSize: "12px",
  fontWeight: 500,
  letterSpacing: "0em",
  textAlign: "center",
  lineHeight: 0.8,
  backgroundColor,
}));

const StatusBox = styled(Box)({
  border: "1px solid #D0D5DD",
  borderRadius: "6px",
  display: "flex",
  gap: "6px",
  alignItems: "center",
  width: "fit-content",
  padding: "2px 8px",
  textTransform: "capitalize",
});

const DeleteEditBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "2rem",
});

const Title = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "left",
});

const Description = styled(Typography)({
  fontSize: "14px",
  fontWeight: 300,
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "left",
});

const AllTasksLists = ({ prompts, onDelete, onEdit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addEllipsis = (text, maxLength = 70) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const handleCardClick = async (id) => {
    // api call to get data for this card and update redux store and redirect to page
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/v1/prompt/${id}/single-prompt`
      );
      if (response.data?.result?.success) {
        const {
          settings: { max_tokens, temperature, modal },
          id,
          title,
          description,
          query,
          fields,
          context,
          status,
        } = response.data?.result?.prompt;
        dispatch(
          updateSettings({
            max_tokens,
            temperature,
            modal,
          })
        );
        dispatch(
          handlePromptData({
            id,
            title,
            description,
            status,
          })
        );
        dispatch(
          updateQueryState({
            value_text: query,
            variables: fields,
          })
        );
        dispatch(
          handleContext({
            value: context,
          })
        );
        navigate("/add-prompt");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledTable className="custom-table">
      <thead>
        <tr>
          <th
            style={{
              width: "60%",
            }}
          >
            Prompt
          </th>
          <th
            style={{
              width: "20%",
            }}
          >
            Status
          </th>
          <th
            style={{
              width: "20%",
            }}
          ></th>
        </tr>
      </thead>
      <tbody>
        {prompts.map((val) => (
          <tr key={val.id}>
            <td
              onClick={() => handleCardClick(val.id)}
              style={{
                padding: "1rem 80px 1rem 1rem",
                cursor: "pointer",
              }}
            >
              <Title>{val.title}</Title>
              <Description>{addEllipsis(val.description)}</Description>
            </td>
            <td>
              <StatusBox>
                <StatusDot
                  backgroundColor={
                    val.status !== "published" ? "#667085" : "#17B26A"
                  }
                ></StatusDot>{" "}
                {val.status.replace("_", " ").replace("published", "live")}
              </StatusBox>
            </td>
            <td
              style={{
                marginLeft: "auto",
              }}
            >
              <DeleteEditBox>
                <DeleteOutlineRoundedIcon
                  onClick={() => onDelete(val.id)}
                  sx={{ color: "#475467", cursor: "pointer" }}
                />
                <EditOutlinedIcon
                  onClick={() =>
                    onEdit({
                      id: val.id,
                      title: val.title,
                      description: val.description,
                    })
                  }
                  sx={{ color: "#475467", cursor: "pointer" }}
                />
              </DeleteEditBox>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default AllTasksLists;
