import { useState } from "react";
import parse from "html-react-parser";

// Material UI
import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "./TabPanel";

export default function BuildTabs({ name, text, data }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={name} />
          <Tab label="JSON" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {parse(text)}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {data}
      </TabPanel>
    </Box>
  );
}
