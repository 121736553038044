import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";

// Material UI
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// Local component
import PromptCard from "../../../components_v2/UI_Components/PromptCard";
import GetStarted from "../../../components_v2/UI_Components/GetStarted";
import Pagination from "../../../components_v2/UI_Components/Pagination";

// Static
import { hideScrollbar, primaryColor } from "../../../static/staticStyles";

const AddPrompts = styled(Box)({
  border: "2px solid " + primaryColor,
  height: 195,
  width: "100%",
  borderRadius: "10px",
  display: "grid",
  placeItems: "center",
  cursor: "pointer",
  backgroundColor: primaryColor,
  boxShadow: "0px 1px 2px 0px #1018280D",
});

const ContentBox = styled("div")({
  marginTop: "1rem",
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
});

const RootContainer = styled("div")({
  backgroundColor: "#EDEDF0",
  borderRadius: "30px",
  padding: "1rem",
  margin: "40px 16px",
  height: "calc(100vh - 171px)",
  position: "relative",
  overflow: "hidden",
});

const BottomSection = styled(Box)({
  position: "absolute",
  bottom: 0,
  width: "100%",
  marginLeft: "-16px",
  backgroundColor: "#EDEDF0",
  borderBottomLeftRadius: "16px",
  borderBottomRightRadius: "16px",
});

const CardContainer = styled(Box)({
  height: "calc(100% - 52px)",
  overflowY: "auto",
  ...hideScrollbar,
});

const DisplayPrompts = ({
  setIsAddingPrompts,
  slug,
  setPrompts,
  prompts,
  promptState,
  setPromptState,
  userId,
  setworkspaceName,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PORT}/v1/workspace/${slug}?userId=${userId}&page=1&limit=8`
        );
        if (response.data?.status === 200) {
          setPrompts(response.data.result.prompts);
          setworkspaceName(response.data.result.workspace_name);
          setPromptState((val) => ({
            ...val,
            totalPages: response.data.result.pages,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [slug]);

  const addPromptsToWorkspace = () => {
    setIsAddingPrompts(true);
  };

  const getPrompts = async (page) => {
    try {
      setPromptState((val) => ({ ...val, page }));
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/v1/workspace/${slug}?userId=${userId}&page=${page}&limit=8`
      );
      if (response.data?.status === 200) {
        setPrompts(response.data.result.prompts);
        setPromptState({
          page,
          totalPages: response.data.result.pages,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removePromptFromWorkspace = async (id) => {
    const temp_prompt = prompts;
    const updatedPrompts = prompts.filter((prompt) => prompt.id !== id);
    setPrompts(updatedPrompts);
    // backend update required
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_PORT}/v1/workspace/${id}/delete?slug=${slug}`
      );
      if (response.data?.status === 200) {
        toast.success("prompt removed successfully", {
          position: "top-center",
          hideProgressBar: true,
          theme: "light",
        });
      }
    } catch (error) {
      setPrompts(temp_prompt);
    }
  };

  const handleCardClick = ({ id }) => {
    navigate(`/use-prompt/${id}`);
  };

  if (prompts.length === 0) {
    return (
      <Box mt={5}>
        <GetStarted
          heading="Add your first task to your workspace"
          subHeading="Use your workspace to win time on your journey"
          handleClick={() => setIsAddingPrompts(true)}
        />
      </Box>
    );
  }

  return (
    <RootContainer>
      <CardContainer>
        <ContentBox
          style={{
            marginTop: 0,
            gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          }}
        >
          <AddPrompts onClick={addPromptsToWorkspace}>
            <AddIcon sx={{ height: 40, width: 40, color: "#fff" }} />
          </AddPrompts>
        </ContentBox>
        {prompts?.length > 0 ? (
          <ContentBox sx={{ marginBottom: "50px" }}>
            {prompts.map((prompt) => (
              <PromptCard
                key={prompt.id}
                title={prompt.title}
                description={prompt.description}
                handleDelete={removePromptFromWorkspace}
                id={prompt.id}
                onCardClick={handleCardClick}
                width="100%"
              />
            ))}
          </ContentBox>
        ) : null}
      </CardContainer>
      <BottomSection>
        <Pagination
          totalPages={promptState.totalPages}
          currPage={promptState.page}
          onNextClick={() => getPrompts(promptState.page + 1)}
          onPrevClick={() => getPrompts(promptState.page - 1)}
        />
      </BottomSection>
    </RootContainer>
  );
};

export default DisplayPrompts;
