import { createSlice } from "@reduxjs/toolkit";

// Helpers
import { removeSpanByIndex } from "../../helpers/spanTagUtils";

const initialState = {
  query_text: "",
  variables: [],

  // var_middle: for adding new variable, and that variable is in the middle of a sentence
  var_middle: {
    _start: "",
    _end: "",
    isVarInMiddle: false,
  },
};

export const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {
    resetQueryState: () => initialState,
    handleQueryUpdate: (state, action) => {
      state.query_text = action.payload.value_text;
    },
    updateVariableInput: (state, action) => {
      const updated_val = state.variables.map((val) => {
        if (val.id === action.payload.var_id) {
          return {
            ...val,
            value: action.payload.var_value,
          };
        }
        return val;
      });
      state.variables = updated_val;
    },
    addNewVariable: (state, action) => {
      const var_id = Math.floor(Math.random() * 100000);

      state.variables.push({
        type: "variable",
        name: action.payload.name,
        placeholder: action.payload.placeholder,
        value: "",
        id: var_id,
        display: true,
        rows: action.payload.rows,
        input_type: action.payload.input_type,
        allowed_formats: action.payload.allowed_formats ?? [],
      });
      if (state.var_middle.isVarInMiddle) {
        state.query_text =
          state.var_middle._start +
          `<span data-id=${var_id}>${action.payload.name}</span>&nbsp;` +
          state.var_middle._end;
      } else {
        state.query_text += `<span data-id=${var_id}>${action.payload.name}</span>&nbsp;`;
      }
    },
    handleRemoveVariable: (state, action) => {
      const updated_val = state.variables.filter(
        (val) => val.id !== action.payload.var_id
      );

      state.variables = updated_val;
      state.query_text = removeSpanByIndex(
        state.query_text,
        +action.payload.var_id
      );
    },
    handleVarMiddleState: (state, action) => {
      if (action.payload.value) {
        state.var_middle.isVarInMiddle = true;
        state.var_middle._start = action.payload.start;
        state.var_middle._end = action.payload.end;
      } else {
        state.var_middle.isVarInMiddle = false;
        state.var_middle._start = "";
        state.var_middle._end = "";
      }
    },
    updateQueryState: (state, action) => {
      state.query_text = action.payload.value_text;
      state.variables = action.payload.variables;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handleQueryUpdate,
  updateVariableInput,
  addNewVariable,
  handleRemoveVariable,
  handleVarMiddleState,
  resetQueryState,
  updateQueryState,
} = querySlice.actions;

export default querySlice.reducer;
