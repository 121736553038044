import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Material UI
import { styled } from "@mui/material/styles";
import { Box, CssBaseline, IconButton, Typography } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

// Context
import { useAuth } from "../../../context/authContext";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  handleWorkspace,
  toggleSidebar,
} from "../../../redux/slices/withsidebarSlice";

// Static
import { primaryColor } from "../../../static/staticStyles";

// Local component
import DrawerBody from "../components/DrawerBody";

const drawerWidth = 296;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "clip",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: "clip",
  width: "84px",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar, // necessary for content to be below app bar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  position: "relative",
  ...(open ? openedMixin(theme) : closedMixin(theme)),
  "& .MuiDrawer-paper": {
    backgroundColor: primaryColor,
    borderRight: "none",
    color: "white",
    ...(open ? openedMixin(theme) : closedMixin(theme)),
  },
}));

const BottomSection = styled("span")({
  position: "absolute",
  bottom: 20,
  width: "100%",
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "24px 20px 0px",
  borderTop: "1px solid #7F56D9",
});

const Logo = styled(Typography)({
  width: "36px",
  fontSize: "24px",
  fontWeight: 900,
  color: "white",
  marginLeft: "9px",
});

const InnerMainContainer = styled(Box)({
  height: "calc(100vh - 32px)",
  backgroundColor: "white",
  borderTopLeftRadius: "30px",
  borderBottomLeftRadius: "30px",
});

const MainRHSContainer = styled(Box)({
  flexGrow: 1,
  padding: "1rem 0rem",
  overflow: "hidden",
});

export default function WithSidebar({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logout, isAuthenticated, user } = useAuth();
  const workspaceStatus = useSelector((state) => state.withsidebar.workspace);
  const open = useSelector((state) => state.withsidebar.is_sidebar_open);

  useEffect(() => {
    if (!user?.name && !user?.email && isAuthenticated) {
      // update condition so that public page is accessable
      handleLogout();
    }
  }, []);

  const handleClose = () => {
    setWorkspaceStatus({
      is_open: false,
    });
    dispatch(toggleSidebar(false));
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleOpen = () => {
    dispatch(toggleSidebar(true));
  };

  const handleHomeRedirection = () => {
    navigate("/");
  };

  const handleListitemClick = (dest) => {
    if (dest) {
      return navigate(dest);
    }
    // open workspace sublist
    setWorkspaceStatus({
      is_open: !workspaceStatus.is_open,
    });
  };

  const setWorkspaceStatus = (val) => {
    dispatch(handleWorkspace(val));
  };

  if (!isAuthenticated)
    return (
      <>
        <CssBaseline />
        {children}
      </>
    );

  return (
    <Box sx={{ display: "flex", backgroundColor: primaryColor }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={handleHomeRedirection}
          >
            <Logo>K</Logo>
          </IconButton>
        </DrawerHeader>
        <DrawerBody
          open={open}
          handleOpen={handleOpen}
          workspaceStatus={workspaceStatus}
          setWorkspaceStatus={setWorkspaceStatus}
          handleListitemClick={handleListitemClick}
          UserId={user.id}
          navigate={navigate}
        />
        <BottomSection>
          {open ? (
            <span>
              <Typography>{user?.name}</Typography>
              <small>{user?.email}</small>
            </span>
          ) : null}
          <span>
            <LogoutOutlinedIcon
              sx={{ cursor: "pointer", marginLeft: "9px", color: "white" }}
              onClick={handleLogout}
            />
          </span>
        </BottomSection>
      </Drawer>
      <MainRHSContainer onClick={handleClose} component="main">
        <InnerMainContainer>{children}</InnerMainContainer>
      </MainRHSContainer>
    </Box>
  );
}
