import axios from "axios";

export const handleWorkspaceState = async ({ user }) => {
  try {
    const userId = (typeof user === "string" ? JSON.parse(user) : user).id;
    const response = await axios.get(
      `${process.env.REACT_APP_PORT}/v1/workspace/user?userId=${userId}`
    );
    if (response.data?.status === 200) {
      return response.data.result.workspaces;
    }
  } catch (error) {
    return error.response;
  }
};
