import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// Material UI
import { Box, Typography, Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// Local components
import OuterContainer from "../components/auth/OuterContainer";

// Helpers
import { validateEmail } from "../helpers/validateEmail";
import { validatePassword } from "../helpers/validatePassword";

// Context
import AuthRedirect from "../components/auth/AuthRedirect";

const Label = styled("label")({
  fontSize: "1rem",
  fontWeight: 500,
  marginBottom: "14px",
  display: "block",
});

const Input = styled(TextField)(({ isinputvalid }) => ({
  width: "637px",
  marginBottom: "2rem",
  "& input": {
    padding: "12px",
    height: "22px",
    ...(isinputvalid
      ? {
          border: "2px solid #6aa272",
          backgroundColor: "#e4f2e6 !important",
          borderRadius: "inherit",
          height: "18px",
        }
      : {}),
  },
}));

const SubmitCTA = styled(Button)({
  backgroundColor: "#E8EEFE",
  color: "black",
  width: "371px",
  fontSize: "20px",
  fontWeight: 500,
  textTransform: "none",
  display: "block",
  margin: "auto",
});

const Heading = styled(Typography)({
  marginTop: "41px",
  fontSize: "24px",
  fontWeight: 500,
  textAlign: "center",
});

const FormContainer = styled(Box)({
  margin: "40px 102px 0px",
});

const BottomSection = styled("div")({
  margin: "31px auto 39px",
  textAlign: "center",
  color: "#868686",
});

const LoginLink = styled(Link)({
  color: "black",
  textDecoration: "none",
});

const VerificationContainer = styled("div")({
  padding: "88px 118px",
  width: "610px",
});

const VerificationTitle = styled(Typography)({
  fontSize: "24px",
  fontWeight: 500,
  marginBottom: "56px",
});

const VerificationMessage = styled(Typography)({
  fontSize: "20px",
  fontWeight: 300,
});

const ServerErrorDisplay = styled("div")({
  textAlign: "center",
  color: "#d32f2f",
});

const IconBox = styled("span")({
  cursor: "pointer",
  position: "absolute",
  right: 10,
  top: 10,
});

function Register() {
  const initialState = { email: "", name: "", password: "" };
  const [error, setError] = useState(initialState);
  const [formValues, setFormValues] = useState(initialState);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validInput, setValidInput] = useState({
    email: false,
    name: false,
    password: false,
  });
  const [serverError, setServerError] = useState("");
  const [displayPassword, setDisplayPassword] = useState(false);

  useEffect(() => {
    setServerError("");
    setValidInput({
      email: validateEmail(formValues.email),
      password: validatePassword(formValues.password),
      name:
        formValues.name.trim().length > 2 && formValues.name.trim().length < 31,
    });
  }, [formValues.email, formValues.name, formValues.password]);

  const disabled = !(
    validInput.email &&
    validInput.name &&
    validInput.password
  );

  const inputValidations = () => {
    let isError = false;
    if (!validateEmail(formValues.email)) {
      isError = true;
      setError((val) => ({ ...val, email: "Incorrect email format" }));
    }
    if (formValues.email.trim().length === 0) {
      isError = true;
      setError((val) => ({ ...val, email: "Email cannot be empty" }));
    }
    if (!validatePassword(formValues.password)) {
      isError = true;
      setError((val) => ({ ...val, password: "Enter valid password" }));
    }
    if (formValues.password.trim().length === 0) {
      isError = true;
      setError((val) => ({ ...val, password: "Password cannot be empty" }));
    }
    if (
      formValues.name.trim().length < 2 &&
      formValues.name.trim().length > 31
    ) {
      isError = true;
      setError((val) => ({
        ...val,
        name: "Name should have atleast 3 characters",
      }));
    }
    if (formValues.name.trim().length === 0) {
      isError = true;
      setError && setError((val) => ({ ...val, name: "Name cannot be empty" }));
    }

    return !isError;
  };

  const handleRegister = async () => {
    // validations
    if (!inputValidations()) return;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/v1/auth/register`,
        formValues
      );

      if (response.data.status === 200) {
        setTimeout(() => setIsSubmitted(true), 1000);
      }
    } catch (error) {
      setServerError(error.response?.data.message || error.response?.message);
      console.log("An error occurred", error);
    }
  };

  return (
    <OuterContainer>
      {isSubmitted ? (
        <VerificationContainer>
          <VerificationTitle>Complete your registration</VerificationTitle>
          <VerificationMessage>
            You will receive an email in a few minutes at the following address:
            {formValues.email}. <br /> <br />
            Please confirm your account by clicking on the verification link.
          </VerificationMessage>
        </VerificationContainer>
      ) : (
        <>
          <Heading>Registration</Heading>
          <FormContainer component="form">
            <Label>Email address</Label>
            <Input
              error={!!error.email}
              helperText={error.email}
              required
              isinputvalid={validInput.email}
              onChange={(event) => {
                setFormValues((val) => ({ ...val, email: event.target.value }));
                setError((val) => ({ ...val, email: "" }));
              }}
              placeholder="example@gmail.com"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleRegister();
                }
              }}
            />

            <Label>Name</Label>
            <Input
              error={!!error.name}
              helperText={error.name}
              required
              isinputvalid={validInput.name}
              onChange={(event) => {
                setFormValues((val) => ({ ...val, name: event.target.value }));
                setError((val) => ({ ...val, name: "" }));
              }}
              placeholder="John Smith"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleRegister();
                }
              }}
            />

            <Label>Password</Label>
            <div style={{ position: "relative" }}>
              <Input
                error={!!error.password}
                isinputvalid={validInput.password}
                type={displayPassword ? "text" : "password"}
                helperText={error.password}
                required
                onChange={(event) => {
                  setFormValues((val) => ({
                    ...val,
                    password: event.target.value,
                  }));
                  setError((val) => ({ ...val, password: "" }));
                }}
                placeholder="Password"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleRegister();
                  }
                }}
              />
              <IconBox onClick={() => setDisplayPassword(!displayPassword)}>
                {displayPassword ? (
                  <VisibilityOffIcon color="action" />
                ) : (
                  <VisibilityIcon color="action" />
                )}
              </IconBox>
            </div>
            <ServerErrorDisplay>{serverError}</ServerErrorDisplay>
            <SubmitCTA disabled={disabled} onClick={handleRegister}>
              Register
            </SubmitCTA>
          </FormContainer>
          <BottomSection>
            Already have an account?{" "}
            <LoginLink to="/login">Login here</LoginLink>
          </BottomSection>
        </>
      )}
    </OuterContainer>
  );
}

const AuthRegister = () => (
  <AuthRedirect>
    <Register />
  </AuthRedirect>
);

export default AuthRegister;
