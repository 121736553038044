import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    confirmation_modal: {
      display: false,
      text: "",
    },
    context_Selection_modal: {
      value: "",
    },
    simulate_cta_disabled: false,
  },
  reducers: {
    handleConfirmationDisplay: (state, action) => {
      if (!action.payload.display) {
        state.confirmation_modal.display = false;
        state.confirmation_modal.text = "";
      } else {
        state.confirmation_modal.display = true;
        state.confirmation_modal.text = action.payload.text;
      }
    },
    handleContextSelection: (state, action) => {
      if (action.payload.value) {
        state.context_Selection_modal.value = action.payload.value;
      }
    },
    handleContext: (state, action) => {
      state.context_Selection_modal.value = action.payload.value;
    },
    handleSimulateState: (state, action) => {
      state.simulate_cta_disabled = action.payload.disabled;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handleConfirmationDisplay,
  handleContextSelection,
  handleContext,
  handleSimulateState,
} = commonSlice.actions;

export default commonSlice.reducer;
