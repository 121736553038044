export const getCursorPosition = (ref) => {
  let cursorPosition = 0;

  const selection = window.getSelection();

  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    const preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(ref.current);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);
    cursorPosition = preSelectionRange.toString().length;
  }

  return cursorPosition;
};

export const getHtmlBeforeAndAfterCursor = (ref) => {
  let htmlBeforeCursor = "";
  let htmlAfterCursor = "";

  const selection = window.getSelection();

  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    const preSelectionRange = range.cloneRange();
    const postSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(ref.current);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);

    const divBefore = document.createElement("div");
    divBefore.appendChild(preSelectionRange.cloneContents());
    htmlBeforeCursor = divBefore.innerHTML
      .replaceAll(": ", ":")
      .replace(/"/g, "");

    postSelectionRange.selectNodeContents(ref.current);
    postSelectionRange.setStart(range.endContainer, range.endOffset);

    const divAfter = document.createElement("div");
    divAfter.appendChild(postSelectionRange.cloneContents());
    htmlAfterCursor = divAfter.innerHTML
      .replaceAll(": ", ":")
      .replace(/"/g, "");
  }

  return { htmlBeforeCursor, htmlAfterCursor };
};
