import { useState } from "react";

// Material UI
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";

const models = [
  {
    id: 0,
    title: "GPT-3.5",
    name: "gpt-3.5-turbo",
    description:
      "GPT-3.5 models can understand and generate natural language or code. Our most capable and cost effective model in the GPT-3.5 family is gpt-3.5-turbo which has been optimized for chat but works well for traditional completions task as well.",
  },
  {
    id: 1,
    title: "DALL-E",
    name: "gpt-3.5-turbo",
    description:
      "DALL-E is an AI system that can create realistic images and art from a description in natural language. We currently support the ability, given a prompt, to create a new image with a certain size, edit and existing image, or create variations of a user provided image",
  },
];

export default function ModelDropdown({ onSelectingModel }) {
  const state_modal = useSelector((state) => state.setting.modal);

  const [model, setModel] = useState(0);

  const handleChange = (event) => {
    setModel(event.target.value);
    onSelectingModel(models[event.target.value]);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <label
          style={{ fontSize: "15px", fontWeight: 700 }}
          id="demo-simple-select-label"
        >
          Model to use
        </label>
        <Select
          style={{ marginTop: "8px" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={model}
          onChange={handleChange}
        >
          {models.map((model) => (
            <MenuItem key={model.id} value={model.id}>
              {model.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
