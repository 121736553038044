// Material UI
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

// Local components
import PromptCard from "../../../components_v2/UI_Components/PromptCard";

// Styles
import { hideScrollbar } from "../../../static/staticStyles";

const StyledCardContainer = styled(Box)(({ selected }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
  gap: "1rem",
  marginTop: "1rem",
  ...(selected
    ? {
        "& div": {
          backgroundColor: "#E8EEFE",
        },
      }
    : {}),
  "& li": {
    padding: 0,
  },
}));

const StyledContainer = styled(Box)({
  marginTop: "24px",
  paddingLeft: "20px",
  overflowY: "auto",
  ...hideScrollbar,
  height: "calc(100vh - 251px)",
  width: "100%",
});

const StyledEmptyContainer = styled(Box)({
  display: "grid",
  placeItems: "center",
  height: "calc(100vh - 351px)",
});

const PromptsToSelect = ({
  promptsToSelect,
  state,
  nextNewPrompts,
  selectPrompts,
}) => {
  if (promptsToSelect.length > 0) {
    return (
      <StyledContainer>
        <StyledCardContainer selected={false}>
          {promptsToSelect.map((prompt) => (
            <PromptCard
              onCardClick={selectPrompts}
              key={prompt.id}
              title={prompt.title}
              description={prompt.description}
              id={prompt.id}
              width="100%"
              handleDelete={null}
            />
          ))}
        </StyledCardContainer>
      </StyledContainer>
    );
  }

  return (
    <StyledEmptyContainer>
      <Typography>Nothing to display</Typography>
    </StyledEmptyContainer>
  );
};

export default PromptsToSelect;
