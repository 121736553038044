import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

// Material UI
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Redux
import { useDispatch } from "react-redux";
import {
  handleWorkspaceDelete,
  handleWorkspaceEdit,
} from "../../../redux/slices/withsidebarSlice";

// Context
import { useAuth } from "../../../context/authContext";

export default function ContextMenu({ element, item }) {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleDelete = async () => {
    // make API call
    dispatch(
      handleWorkspaceDelete({
        slug: item.slug,
      })
    );
    try {
      await axios.delete(
        `${process.env.REACT_APP_PORT}/v1/workspace/delete?slug=${item.slug}&UserId=${user.id}`
      );
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong", {
        position: "bottom-center",
        hideProgressBar: true,
        theme: "light",
      });
    }
    handleClose();
  };

  const handleEdit = () => {
    // this displays textfield on sidebar
    dispatch(
      handleWorkspaceEdit({
        slug: item.slug,
      })
    );
  };

  return (
    <div onContextMenu={handleContextMenu} style={{ cursor: "context-menu" }}>
      {element}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleEdit}>Rename</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
}
