export const hideScrollbar = {
  // hiding scrollbar
  scrollbarWidth: "thin", // Note: Firefox does not support hiding the scrollbar, so we'll make it thin
  /* For WebKit-based browsers (Safari and Chrome) */
  "&::-webkit-scrollbar": {
    width: "1px" /* Adjust as needed to make the scrollbar very thin */,
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
  "&::-webkit-scrollbar-thumb": {
    /* Make the scrollbar thumb transparent */
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};

export const primaryColor = "#6941C6";
