import React from "react";

// Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { styled } from "@mui/system";

// Redux
import { useSelector } from "react-redux";

const ContentBox = styled(DialogContent)({
  height: "70px",
  display: "grid",
  placeItems: "center",
  "& p": { fontSize: "20px" },
});

function ConfirmationModal({ onClose }) {
  const { text, display: open } = useSelector(
    (state) => state.common.confirmation_modal
  );

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <ContentBox>
        <DialogContentText>{text}</DialogContentText>
      </ContentBox>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationModal;
