import { useState } from "react";

// Material UI
import { styled } from "@mui/system";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";

// Local Components
import Fields from "./components/Fields";
import AddFields from "./components/AddFields";
import PromptData from "./components/PromptData";

// Redux
import { useDispatch } from "react-redux";
import { addNewVariable } from "../../redux/slices/querySlice";

// Static
import { hideScrollbar } from "../../static/staticStyles";
import ModelDropdown from "../../components_v2/prompt_builder_page/ModelDropdown";
import Settings from "../../components_v2/prompt_builder_page/Settings";

const SidebarRoot = styled("div")({
  width: "99%",
  height: "100%",
  padding: "0rem 1rem 3rem 2rem",
  backgroundColor: "white",
  overflowY: "auto",
  ...hideScrollbar,
});

function Sidebar({ handleSettingUpdate }) {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const INITIAL_VAR_STATE = {
    name: "",
    placeholder: "",
    type: "text",
    rows: 1,
    error: false,
  };
  const [var_state, setVar_state] = useState(INITIAL_VAR_STATE);

  const handleEditing = (val) => setIsEditing(val);

  const handleAddLabel = () => {
    if (var_state.placeholder.trim() === "")
      return setVar_state({ ...var_state, error: true });
    handleClose();
    dispatch(
      addNewVariable({
        ...var_state,
      })
    );
    handleEditing(true);
    setVar_state(INITIAL_VAR_STATE);
    setIsEditing(true);
  };

  const handleOpen = () => setDisplayModal(true);
  const handleClose = () => setDisplayModal(false);

  const handledisplayModal = (values) => {
    handleOpen();
    setVar_state({
      ...var_state,
      ...values,
    });
  };

  return (
    <SidebarRoot>
      <div>
        <ModelDropdown onSelectingModel={handleSettingUpdate} />
        {/* prompt editing field*/}
        <Fields
          isEditing={isEditing}
          handleEditing={handleEditing}
          handledisplayModal={handledisplayModal}
        />
      </div>

      {isEditing ? (
        <Settings handleSettingUpdate={handleSettingUpdate} />
      ) : null}

      {/* modal to update variables */}
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={displayModal}
        onClose={handleClose}
      >
        <DialogTitle>Add label</DialogTitle>
        <DialogContent>
          <TextField
            style={{ width: "100%" }}
            autoFocus
            type="text"
            onChange={(e) => {
              setVar_state({
                ...var_state,
                placeholder: e.target.value,
                error: false,
              });
            }}
            error={var_state.error}
            variant="standard"
            helperText="Enter label name"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleAddLabel();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddLabel}>Add</Button>
        </DialogActions>
      </Dialog>
    </SidebarRoot>
  );
}

export default Sidebar;
