// Material UI
import { Divider, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

// Static
import { primaryColor } from "../../../static/staticStyles";

const StyledListContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});

const StyledListItem = styled("div")({
  padding: "28px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "4px",
  backgroundColor: primaryColor,
  borderRadius: "8px",
  color: "white",
});

const StyledDeleteIcon = styled(ClearRoundedIcon)({
  cursor: "pointer",
  height: "20px",
  width: "20px",
  color: "white",
});

const PromptsOnWorkspace = ({ selectedPrompts, unselectPrompt }) => {
  if (selectedPrompts.length > 0) {
    return (
      <StyledListContainer>
        {selectedPrompts.map((prompt) => (
          <div>
            <StyledListItem>
              <Typography>{prompt.title}</Typography>
              <StyledDeleteIcon onClick={() => unselectPrompt(prompt)} />
            </StyledListItem>
            <Divider />
          </div>
        ))}
      </StyledListContainer>
    );
  }

  return <Typography>No prompts selected yet</Typography>;
};

export default PromptsOnWorkspace;
