// Material UI
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Container = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "1rem",
});

const CTAContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "12px",
});

const CTA = styled(Button)({
  color: "#D0D5DD",
  border: "1px solid #D0D5DD",
  borderRadius: "8px",
  backgroundColor: "white",
  "&:hover": {
    color: "#D0D5DD",
    border: "1px solid #D0D5DD",
  },
});

const PageInfo = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "left",
});

const Pagination = ({
  currPage = 0,
  totalPages = 0,
  onNextClick,
  onPrevClick,
}) => {
  return (
    <Container>
      <CTAContainer>
        <CTA disabled={currPage === 1} onClick={onPrevClick} variant="outlined">
          Previous
        </CTA>
        <CTA
          disabled={currPage >= totalPages}
          onClick={onNextClick}
          variant="outlined"
        >
          Next
        </CTA>
      </CTAContainer>
      <Box>
        <PageInfo>
          Page {currPage} of {totalPages}
        </PageInfo>
      </Box>
    </Container>
  );
};

export default Pagination;
