import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

// Material UI
import { styled } from "@mui/system";

// Helpers
import { extractLastHTMLTagAndDataId } from "../../helpers/extractLastHTMLTagAndDataId";
import {
  getCursorPosition,
  getHtmlBeforeAndAfterCursor,
} from "../../helpers/getCursorPosition";

// Hooks
import { useCursorPositionAtEnd } from "../../helpers/hooks/useCursorPositionAtEnd";

const EditorInput = styled("div")({
  height: "130px",
  border: "1px solid #ccc",
  borderRadius: "0.25rem",
  backgroundColor: "rgba(0, 0, 0, 0.04)",
  padding: "1rem",
  lineHeight: 2,
  overflowY: "auto",
  "& span": {
    backgroundColor: "white",
    padding: "0.3rem",
    borderRadius: "0.25rem",
    color: "rgba(0, 0, 0, 0.8)",
  },
});

function Editor({
  value,
  onChange,
  htmlContent,
  displayHTML = false,
  editorRef,
  variables,
}) {
  /**
   *
   * About props: -
   *
   * value: to display default content on editor
   * onChange: to get text when updated on editor
   * displayHTML: (boolean) - determines which content to display inside editor: true = display htmlContent, false = display value
   * htmlContent: displayed when displayHTML is true. It displays html component inside editor
   */

  const [content, setContent] = useState("");

  useEffect(() => {
    if (value) {
      setContent(value);
    }
  }, [variables]);

  useCursorPositionAtEnd(editorRef, content); // Restore cursor position after content updates

  const handleKeyDown = (event) => {
    let updatedContent_html = event.target.innerHTML
      ?.replaceAll(": ", ":")
      .replace(/"/g, "");

    const cursorPosition = getCursorPosition(editorRef);

    if (event.key === "Backspace") {
      if (cursorPosition < event.target.innerText.length) {
        // Deleting content from middle (and not end)

        const { htmlBeforeCursor, htmlAfterCursor } =
          getHtmlBeforeAndAfterCursor(editorRef);
        if (
          htmlBeforeCursor.endsWith("</span>") ||
          htmlBeforeCursor.endsWith("</span>&nbsp;")
        ) {
          // Deleting variable at the middle of the string
          event.preventDefault();
          const { content, id } = extractLastHTMLTagAndDataId(htmlBeforeCursor);
          onChange &&
            onChange({ html: `${content} ${htmlAfterCursor}`, var_id: id });
          setContent(`${content} ${htmlAfterCursor}`);
        }
      } else {
        if (
          updatedContent_html.endsWith("</span>") ||
          updatedContent_html.endsWith("</span>&nbsp;")
        ) {
          // Deleting variable at the end of the string and the variable placeholder is at the end

          event.preventDefault();
          const { content, id } =
            extractLastHTMLTagAndDataId(updatedContent_html);
          onChange && onChange({ html: content, var_id: id });
          setContent(content);
        }
      }
    }
  };

  const handleInput = (event) => {
    let updatedContent_html = event.target.innerHTML
      ?.replaceAll(": ", ":")
      .replace(/"/g, "");
    onChange && onChange({ html: updatedContent_html });
  };

  return (
    <EditorInput
      contentEditable="true"
      onKeyDown={handleKeyDown}
      onInput={handleInput}
      ref={editorRef}
    >
      {displayHTML ? htmlContent : parse(content)}
    </EditorInput>
  );
}

export default React.memo(Editor);
