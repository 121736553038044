import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { SSE } from "sse";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Material UI
import { styled } from "@mui/system";
import {
  Box,
  Paper,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

// Local component
import WithSidebar from "../components/sidebar/HOC/WithSidebar";
import { PrimaryCTA } from "../components_v2/UI_Components/PrimaryCTA";
import CustomFileInput from "../components_v2/UI_Components/CustomFileInput";

// Helpers
import { extractTextContent, updateSpanText } from "../helpers/spanTagUtils";

// Static
import { hideScrollbar, primaryColor } from "../static/staticStyles";
import { toolbarModule } from "../static/toolbarConfig";

const StyledUsePromptContainer = styled("div")({
  minHeight: "100vh",
  padding: "32px 60px 32px 24px",
});

const StyledHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "32px",
});

const StyledBody = styled("div")({
  display: "flex",
  gap: "1.5rem",
});

const LHS = styled("div")({
  width: "400px",
  height: "calc(100vh - 162px)",
  overflow: "auto",
  ...hideScrollbar,
});

const RHS = styled("div")({
  flex: 1,
  height: "calc(100vh - 162px)",
  "& body": {
    overflow: "auto",
    ...hideScrollbar,
    height: "calc(100vh - 283px)",
  },
  "& .quill .ql-toolbar": {
    position: "absolute",
    top: "-40px",
    left: 0,
    border: "unset",
  },
  "& .ql-container": {
    border: "unset",
    fontSize: "16px",
  },
});

const StyledInput = styled(TextField)(({ padding, fileName }) => ({
  borderRadius: "10px",
  width: "100%",
  "& div": {
    borderRadius: "inherit",
  },
  "& input": {
    height: "fit-content",
    ...(padding !== null
      ? {
          padding: 0,
        }
      : {}),
  },
  "& input::-webkit-file-upload-button": {
    visibility: "hidden",
    marginLeft: "-80px",
  },
}));

const CopyIcon = styled(ContentCopyRoundedIcon)(({ disabled }) => ({
  color: disabled ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0.87)",
  marginRight: "0.5rem",
  cursor: disabled ? "not-allowed" : "pointer",
  padding: "8px",
  borderRadius: "50%",
  height: "40px",
  width: "40px",
  boxSizing: "border-box",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.02)",
  },
}));

const DownloadIcon = styled(FileDownloadRoundedIcon)(({ disabled }) => ({
  color: disabled ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0.87)",
  cursor: disabled ? "not-allowed" : "pointer",
  padding: "8px",
  borderRadius: "50%",
  height: "40px",
  width: "40px",
  boxSizing: "border-box",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.02)",
  },
}));

const CircularLoader = styled(CircularProgress)({
  height: "24.5px !important",
  width: "24.5px !important",
});

const PrimaryLoader = styled(CircularProgress)({
  height: "24px !important",
  width: "24px !important",
  color: primaryColor,
  marginTop: "2px",
});

function UsePrompt() {
  const { id } = useParams();
  const responseRef = useRef("");
  const textRef = useRef(null);
  const [promptData, setPromptData] = useState(null);
  const [fieldData, setFieldData] = useState([]);
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSinglePrompt(id);
  }, []);

  // disable state of generate CTA
  const disabled = fieldData?.some((field) =>
    typeof field?.value === "string"
      ? field?.value?.trim() === ""
      : !field?.value
  );

  const getSinglePrompt = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/v1/prompt/${id}/single-prompt`
      );
      if (response.data?.result?.success) {
        setPromptData(response.data?.result?.prompt);
        const fields = response.data?.result?.prompt.fields.map((val) => ({
          ...val,
          value: "",
        }));
        setFieldData(fields);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!", {
        position: "top-center",
        hideProgressBar: true,
        theme: "light",
      });
    }
  };

  const copyResponse = (textToCopy, type = "Text") => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success(type + " copied", {
          position: "top-center",
          hideProgressBar: true,
          theme: "light",
        });
      })
      .catch((error) => {
        toast.error("Something went wrong! Please try again.", {
          position: "top-center",
          hideProgressBar: true,
          theme: "light",
        });
      });
  };

  const downloadFile = () => {
    if (disabled || loading || response.trim() === "") return;
    const editor = textRef.current.getEditor();
    const text = editor.getText();

    const blob = new Blob([text], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${promptData?.title}.txt`;
    a.style.display = "none";
    document.body.appendChild(a);

    a.click();

    window.URL.revokeObjectURL(url);
  };

  // this is displayed on screen
  const htmlQuery = updateSpanText(
    promptData?.query ?? "",
    fieldData?.map((val) => ({
      value:
        typeof val?.value === "object"
          ? val?.value.name
          : val?.value?.trim()
          ? val?.value
          : val?.name,
      id: val?.id,
    }))
  );

  let json_htmlQuery; // if input type file is present in fieldData
  if (fieldData.filter((val) => val.input_type === "file").length > 0) {
    json_htmlQuery = updateSpanText(
      promptData?.query ?? "",
      fieldData?.map((val) => ({
        value:
          typeof val?.value === "object"
            ? val?.value.data
            : val?.value?.trim()
            ? val?.value
            : val?.name,
        id: val?.id,
      }))
    );
  }

  // this is for API call
  const prompt_query = extractTextContent(json_htmlQuery ?? htmlQuery);

  const generateResponse = async () => {
    setLoading(true);
    setResponse("");
    try {
      const queryData = JSON.stringify({
        model: promptData.settings.modal.name,
        temperature: promptData.settings.temperature,
        max_tokens: promptData.settings.maxTokens,
        stream: true,
        messages: [
          {
            role: "user",
            content: `Format the response as HTML content. ${prompt_query}`,
          },
        ],
      });

      const API_KEY = process.env.REACT_APP_OPEN_API_KEY;
      const apiUrl = "https://api.openai.com/v1/chat/completions";

      let source = new SSE(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
        },
        method: "POST",
        payload: queryData,
      });

      source.addEventListener("message", (e) => {
        if (e.data !== "[DONE]") {
          let payload = JSON.parse(e.data);
          let text = payload.choices[0].delta?.content;
          if (text !== "\n" && text !== undefined && text?.trim() !== "") {
            responseRef.current = responseRef.current + text;
            setResponse(responseRef.current);
          }
        } else {
          source.close();
        }

        source.addEventListener("readystatechange", (e) => {
          if (e.readyState >= 2) {
            setLoading(false);
            responseRef.current = "";
          }
        });
      });

      source.stream();
    } catch (error) {
      setLoading(false);
      console.error("Failed to generate response:", error);
    }
  };

  const handleFileUpload = async ({ file, id }) => {
    if (!file) return;
    // show error for file size > 50kb. For 1MB, 1024 * 1024
    if (file.size > 50 * 1024) {
      return toast.error("File size exceeds limit. Try again.", {
        position: "top-center",
        hideProgressBar: true,
        theme: "light",
      });
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/v1/upload-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data?.result?.success) {
        const fields = fieldData.map((field) => {
          if (field.id === id) {
            return {
              ...field,
              value: {
                name: response.data?.result?.file_name,
                data: response.data?.result?.data,
              },
            };
          } else return field;
        });
        setFieldData(fields);
        toast.success("File data extracted", {
          position: "top-center",
          hideProgressBar: true,
          theme: "light",
        });
      } else {
        toast.error("File upload failed", {
          position: "top-center",
          hideProgressBar: true,
          theme: "light",
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error uploading file", {
        position: "top-center",
        hideProgressBar: true,
        theme: "light",
      });
      console.error("Error uploading file:", error);
    }
  };

  const handleFieldUpdate = async ({ id, e, allowed_formats, input_type }) => {
    if (input_type !== "file") {
      const updatedField = fieldData.map((field) => {
        if (field.id === id) {
          return { ...field, value: e.target.value };
        }
        return field;
      });
      setFieldData(updatedField);
      return;
    }
    const value = e.target.value;
    const ext = value.split(".")[value.split(".").length - 1];

    if (!allowed_formats.includes(ext)) {
      return toast.error("Format not allowed", {
        position: "top-center",
        hideProgressBar: true,
        theme: "light",
      });
    }
    await handleFileUpload({ file: e.target.files[0], id });
  };

  return (
    <StyledUsePromptContainer>
      <StyledHeader>
        <Typography sx={{ fontWeight: 500, fontSize: "28px" }}>
          {promptData?.title ?? "Name of the prompt"}
        </Typography>
        <Box>
          <PrimaryCTA
            ctaClick={() => copyResponse(window.location.href, "Link")}
          >
            Share this prompt
          </PrimaryCTA>
        </Box>
      </StyledHeader>
      <StyledBody>
        <LHS>
          {fieldData?.length > 0
            ? fieldData.map(
                ({
                  id,
                  placeholder: input_name,
                  input_type,
                  rows,
                  allowed_formats,
                  value,
                }) => (
                  <Box sx={{ marginBottom: "32px" }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        marginBottom: "12px",
                      }}
                    >
                      {input_name}
                    </Typography>
                    {input_type === "file" ? (
                      <CustomFileInput
                        onFileChange={(e) =>
                          handleFieldUpdate({
                            id,
                            e,
                            allowed_formats,
                            input_type,
                          })
                        }
                      />
                    ) : (
                      <StyledInput
                        key={`${input_type}-${id}`}
                        id={`${input_type}-${id}`}
                        multiline={input_type === "text"}
                        rows={rows ?? 1}
                        type={input_type ?? "text"}
                        padding={input_type === "file" ? 0 : null}
                        fileName={value?.name}
                        onChange={(e) =>
                          handleFieldUpdate({
                            id,
                            e,
                            allowed_formats,
                            input_type,
                          })
                        }
                        placeholder="Copy / Past your content"
                        inputProps={{ accept: allowed_formats }}
                      />
                    )}
                  </Box>
                )
              )
            : null}
          <PrimaryCTA
            disabled={disabled || loading}
            ctaClick={generateResponse}
          >
            {loading ? (
              <CircularLoader color={disabled ? "inherit" : undefined} />
            ) : (
              "Generate"
            )}
          </PrimaryCTA>
        </LHS>
        <RHS>
          <Paper
            sx={{
              height: "inherit",
              padding: "32px",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <div
              style={{
                textAlign: "right",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {loading ? (
                <span style={{ paddingRight: "16px" }}>
                  <PrimaryLoader />
                </span>
              ) : null}
              <CopyIcon
                disabled={disabled || loading || response.trim() === ""}
                onClick={() => {
                  if (disabled || loading || response.trim() === "") return;
                  const editor = textRef.current.getEditor();
                  const text = editor.getText();
                  // document.getElementsByClassName("ql-container ql-snow")[0]
                  copyResponse(text);
                }}
              />
              <DownloadIcon
                disabled={disabled || loading || response.trim() === ""}
                onClick={downloadFile}
              />
            </div>
            <ReactQuill
              ref={textRef}
              theme="snow"
              value={response}
              onChange={setResponse}
              modules={toolbarModule}
            />
          </Paper>
        </RHS>
      </StyledBody>
    </StyledUsePromptContainer>
  );
}

const UsePromptWithSidebar = () => (
  <WithSidebar>
    <UsePrompt />
  </WithSidebar>
);

export default UsePromptWithSidebar;
