import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// Material UI
import { Typography } from "@mui/material";
import { styled } from "@mui/system";

// Local components
import OuterContainer from "../components/auth/OuterContainer";

// Context
import { useAuth } from "../context/authContext";
import AuthRedirect from "../components/auth/AuthRedirect";
import { MessageBox } from "../components/UIComponents/MessageBox";

const VerificationContainer = styled("div")({
  padding: "88px 118px",
  width: "610px",
});

const VerificationTitle = styled(Typography)({
  fontSize: "24px",
  fontWeight: 500,
  marginBottom: "56px",
});

const UserVerify = () => {
  const { register } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState({
    title: "",
    para: "",
  });

  useEffect(() => {
    if (!!location.search) {
      (async () => {
        try {
          // extract id from location
          const accessID = location.search.split("id=")[1];
          const response = await axios.post(
            `${process.env.REACT_APP_PORT}/v1/auth/verify/${accessID}`
          );
          setTimeout(() => setLoading(false), 1000);
          if (response?.status === 200) {
            if (response.data?.result?.token)
              register({
                token: response.data.result.token,
                user: response.data.result.user,
              });
            setContent({
              title: "Congralutions!",
              para: (
                <>
                  Your Remit account has been successfully created.
                  <br /> <br />
                  You will be redirected in a few seconds.
                </>
              ),
            });
            setTimeout(() => navigate("/home"), 3000);
          } else {
            setContent({
              title: "Account not verified. Please try again.",
              para: "",
            });
          }
        } catch (error) {
          console.log(error);
          setTimeout(() => setLoading(false), 1000);
          setContent({
            title: "Account not verified. Please try again.",
            para: "",
          });
        }
      })();
    }
  }, []);

  return (
    <OuterContainer>
      {loading ? (
        <VerificationContainer>
          <VerificationTitle style={{ textAlign: "center" }}>
            Verification in progress...
          </VerificationTitle>
          <img
            src="/assets/loader.gif"
            height={100}
            style={{ display: "block", margin: "auto" }}
          />
        </VerificationContainer>
      ) : (
        <MessageBox heading={content.title} content={content.para} />
      )}
    </OuterContainer>
  );
};

const AuthVerify = () => (
  <AuthRedirect>
    <UserVerify />
  </AuthRedirect>
);

export default AuthVerify;
